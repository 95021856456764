.mo-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mo-modal-content {
  background: white;
  padding: 1.82rem; /* = 20px */
  border-radius: 0.73rem; /* = 8px */
  width: 27.27rem; /* = 300px */
  box-shadow: 0 0.18rem 0.91rem rgba(0, 0, 0, 0.1); /* = 2px 10px */
}

.mo-modal-title {
  margin-bottom: 1.36rem; /* = 15px */
}

.mo-input-wrapper {
  margin-bottom: 1.36rem; /* = 15px */
}

.mo-password-input {
  width: 100%;
  padding: 0.91rem; /* = 10px */
  border: 0.09rem solid #ccc; /* = 1px */
  border-radius: 0.36rem; /* = 4px */
}

.mo-signin-button {
  padding: 0.73rem 1.64rem; /* = 8px 18px */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.36rem; /* = 4px */
  cursor: pointer;
  font-size: 1rem; /* = 11px */
}

.mo-signin-button:hover {
  background-color: #0056b3;
}

.mo-bold {
  font-weight: bold;
}

.mo-close {
  margin-top: 0.91rem; /* = 10px */
}