@import url('variables.css');
* {
  letter-spacing: 0.035rem !important;
}

hr {
  border: var(--border) !important;
  border-width: 0px 0px 0.5px 0px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-family: 'SF Pro Display Bold', sans-serif !important;
  color: var(--text-color);
}

.dark {
  background-color: #030902 !important;
  color: #ffffff !important;
}

@font-face {
  font-family: 'SF Pro Display Regular';
  src: url('../assets/font/SF-Pro-Display-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro Display Medium';
  src: url('../assets/font/SF-Pro-Display-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro Display Bold';
  src: url('../assets/font/SF-Pro-Display-Bold.otf') format('opentype');
}

* {
  font-family: 'SF Pro Display Medium', sans-serif;
  font-weight: 0 !important;
}

.css-1vlsb4t-placeholder {
  font-family: 'SF Pro Display Bold', sans-serif !important;
  font-weight: 0 !important;
}

.bold {
  font-family: 'SF Pro Display Bold', sans-serif !important;
  font-weight: 0 !important;
}

.medium {
  font-family: 'SF Pro Display Medium', sans-serif !important;
  font-weight: 0 !important;
}

.regular {
  font-family: 'SF Pro Display Regular', sans-serif !important;
  font-weight: 0 !important;
}

@font-face {
  font-family: 'Inter Regular';
  src: url('../assets/font/Inter-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Inter Medium';
  src: url('../assets/font/Inter-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Inter Bold';
  src: url('../assets/font/Inter-Bold.otf') format('opentype');
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-family: 'Inter Bold', sans-serif !important;
  color: var(--text-color);
}

.dark {
  background-color: #030902 !important;
  color: #ffffff !important;
}

* {
  font-family: 'Inter Medium', sans-serif;
  font-weight: 0 !important;
}

.css-1vlsb4t-placeholder {
  font-family: 'Inter Bold', sans-serif !important;
  font-weight: 0 !important;
}

.bold {
  font-family: 'Inter Bold', sans-serif !important;
  font-weight: 0 !important;
}

.medium {
  font-family: 'Inter Medium', sans-serif !important;
  font-weight: 0 !important;
}

.regular {
  font-family: 'Inter Regular', sans-serif !important;
  font-weight: 0 !important;
}

/*Select svg */
.css-15c70wz svg {
  width: 1.5rem;
  height: 1.5rem;
}
.css-1wy0on6 svg {
  width: 2rem;
  height: 2rem;
}

.react-datepicker__navigation {
  width: 2rem !important;
  height: 2rem !important;
}
.css-me7vn6-multiValue {
}
.css-bbvmix {
}
/*Material UI */
.MuiPaper-root * {
  font-family: 'SF Pro Display Medium', sans-serif;
  color: var(--text-color);
  font-weight: 0 !important;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

textarea:focus,
input:focus {
  outline: none;
}

/* React DatePicker Styles */
/* Calendar Background */

.react-datepicker {
  background-color: var(--main-color) !important;
  border: var(--border) !important;
  color: var(--text-color) !important;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.react-datepicker__header {
  background-color: var(
    --background-color
  ) !important; /* Using --background-color for the header */
  color: var(
    --text-color
  ) !important; /* Using --text-color for the text in the header */
  border-bottom: 0.5px solid var(--text-color) !important;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}
/* Navigation buttons (previous/next) */
.react-datepicker__navigation {
  background-color: var(--background-color) !important;
  border-radius: 50% !important;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--text-color) !important;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.react-datepicker__navigation--next {
  border-left-color: var(--text-color) !important;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

/* Day button styles */
.react-datepicker__day,
.react-datepicker__day-name {
  color: var(--text-color) !important;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.react-datepicker__day--selected {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

/* Hover effect for the days */
.react-datepicker__day:hover {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

/* Today button style */
.react-datepicker__today-button {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  border: none;
  padding: 0.5rem 1rem;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

/* Time list */
.react-datepicker__time-container {
  background-color: var(--main-color) !important;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

/* Time list items */
.react-datepicker__time-list-item {
  color: var(--text-color) !important;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.react-datepicker__time-list-item--selected {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}
/* Ensure that disabled dates are greyed out */
.react-datepicker__day--disabled {
  color: #c4bfbe !important; /* Light grey color for disabled dates */
  background-color: transparent !important; /* Ensure no background */
  cursor: not-allowed !important; /* Show not-allowed cursor */
  pointer-events: none; /* Disable interaction */
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.react-datepicker__current-month {
  color: var(--text-color) !important;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

/* For commonly used buttons */
.greenButton {
  background-color: var(--stock-change-pos) !important;
  color: black !important;
}
.goldButton {
  background-color: #e6ac00 !important;
  color: #000 !important;
}

.moreButtonPadding {
  padding: 1.75rem 4.375rem !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Back button*/
.bb-container {
  background: none;
  border: none;
  color: var(--action-grey);
  font-size: var(--font-med-l);
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
}

.mobile-screen {
  display: none;
}

@media only screen and (max-width: 600px) {
  .mobile-screen {
    height: 100vh;
    width: 100vw;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    background-color: #030902;
    display: block;
  }
  .mobile-heading {
    font-family: 'SF Pro Display Bold', sans-serif !important;
    color: #fff;
    text-align: center;
    font-size: 30px;
    padding-top: 25px !important;
  }
  .mobile-heading + p {
    font-family: 'SF Pro Display Medium';
    color: #ffffff;
    font-size: 12px;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    line-height: 20px;
  }
  .mobile-screen button {
    font-size: 17px;
    color: #ffffff;
    padding: 12px 25px;
    background-color: rgba(255, 255, 255, 0.1);
    font-family: 'SF Pro Display Bold', sans-serif !important;
    border-radius: 100px;
    border: none;
    display: block;
    margin: 10px auto;
  }
  .mobile-screen button:hover {
    background-color: #00ff00 !important;
    color: #000 !important;
  }
  .mobile-screen button.active {
    background-color: #00ff00 !important;
    color: #000 !important;
  }
  .mobile-heading div {
    overflow: auto;
  }
  .ulls {
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    white-space: nowrap !important;
  }
  .mobile-heading div .ulls li {
    font-family: 'SF Pro Display Bold', sans-serif !important;
    color: #ffffff;
    display: inline-block;
    margin: 0 30px;
  }
  .ulls li {
    font-size: 12px !important;
    list-style-type: none !important;
    padding: 10px 15px !important;
  }
  .mobile-heading div ul li span {
    font-family: 'SF Pro Display Medium', sans-serif !important;
    color: #fff;
  }
  .color-green {
    font-family: 'SF Pro Display Bold', sans-serif !important;
    color: #00ff00 !important;
  }
  .color-red {
    font-family: 'SF Pro Display Bold', sans-serif !important;
    color: #bc0c0c !important;
  }
  .over_hid {
    overflow: auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .mob-modal {
    background-color: #000000;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 15px;
    padding: 20px;
  }

  .btn-summit-mob {
    font-size: 14px !important;
    color: #000000 !important;
    padding: 8px 25px !important;
    background-color: #00ff00 !important;
    font-family: 'SF Pro Display Bold', sans-serif !important;
    border-radius: 100px;
    border: none;
    display: block;
    margin: 0 auto !important;
  }
}

.npt-mobile {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 4px !important;
  padding: 7px 10px !important;
  font-size: 14px !important;
  color: #ffffff !important;
  margin-bottom: 12px !important;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100% !important;
  display: block !important;
}

[data-theme='light'] {
  .sidebars-analytics {
    min-width: 250px !important;
    background-color: #e5e5e5;
    padding: 5px 10px !important;
    border-radius: 5px !important;
    height: 300px;
  }
  .sidebars-analytics div ul {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sidebars-analytics div ul li {
    list-style-type: none !important ;
    font-size: 13px !important;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    background-color: #d5d5d5;
    padding: 12px 15px !important;
    border-radius: 5px !important;
    margin: 7px 0 !important;
    cursor: pointer;
  }
  .sidebars-analytics div ul li svg {
    height: 14px !important;
    width: 14px !important;
    margin-right: 7px !important;
  }
  .filterdate {
    font-size: 13px !important;
    color: #0e0e0e !important;
  }

  .react-datepicker__input-container input {
    background-color: #f2f2f2 !important;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 11px !important;
    display: block;
    margin-top: 8px;
  }

  .card-records {
    border-radius: 7px;
    padding: 15px;
    background-color: #ebebeb;
    display: inline-block;
    width: 200px;
    margin-right: 15px;
  }
  .card-records p {
    font-size: 13px !important;
  }
  .card-records h2 {
    font-size: 26px !important;
    font-weight: 600 !important;
    color: #161616;
  }
  .analytics-table-card {
    padding: 15px;
    background-color: #ebebeb;
    width: 445px !important;
    border-radius: 7px;
  }
  .analytics-table {
    width: 100% !important;
  }
  .analytics-table thead tr th {
    font-size: 12px !important;
    background-color: #dddddd;
    padding: 10px 15px;
  }
  .analytics-table tbody tr td {
    font-size: 12px !important;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .analytics-table thead tr th:nth-child(1) {
    border-radius: 7px 0 0 7px;
  }
  .analytics-table thead tr th:nth-last-child(1) {
    border-radius: 0 7px 7px 0;
  }
  .fulltable {
    width: 100% !important;
  }
  .analytics-list .paginations ul {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .analytics-list .paginations ul li {
    list-style-type: none;
    font-size: 13px;
    margin: 0 8px;
  }
}

[data-theme='dark'] {
  .sidebars-analytics {
    min-width: 250px !important;
    background-color: #1d1d1f;
    padding: 5px 10px !important;
    border-radius: 5px !important;
    height: 300px;
  }
  .mo-modal-title {
    color: #000000 !important;
  }
  .sidebars-analytics div ul {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sidebars-analytics div ul li {
    list-style-type: none !important ;
    font-size: 13px !important;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    background-color: #161616;
    padding: 12px 15px !important;
    border-radius: 5px !important;
    margin: 7px 0 !important;
    cursor: pointer;
  }
  .sidebars-analytics div ul li svg {
    height: 14px !important;
    width: 14px !important;
    margin-right: 7px !important;
  }
  .filterdate {
    font-size: 13px !important;
    color: #ffffff !important;
  }

  .react-datepicker__input-container input {
    background-color: #080808 !important;
    border: 1px solid #2d2d2d;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 11px !important;
    display: block;
    margin-top: 8px;
  }

  .card-records {
    border-radius: 7px;
    padding: 15px;
    background-color: #0f0f0f;
    display: inline-block;
    width: 200px;
    margin-right: 15px;
  }
  .card-records p {
    font-size: 13px !important;
  }
  .card-records h2 {
    font-size: 26px !important;
    font-weight: 600 !important;
    color: #cdcdcd;
  }
  .analytics-table-card {
    padding: 15px;
    background-color: #0f0f0f;
    width: 445px !important;
    border-radius: 7px;
  }
  .analytics-table {
    width: 100% !important;
  }
  .analytics-table thead tr th {
    font-size: 12px !important;
    background-color: #161616;
    padding: 10px 15px;
  }
  .analytics-table tbody tr td {
    font-size: 12px !important;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .analytics-table thead tr th:nth-child(1) {
    border-radius: 7px 0 0 7px;
  }
  .analytics-table thead tr th:nth-last-child(1) {
    border-radius: 0 7px 7px 0;
  }
  .fulltable {
    width: 100% !important;
  }
  .analytics-list .paginations ul {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .analytics-list .paginations ul li {
    list-style-type: none;
    font-size: 13px;
    margin: 0 8px;
  }
}
