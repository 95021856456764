@import url('variables.css');

.privacy-container {
    text-align: left;
    margin: 0rem auto;
    padding: 3rem 3rem;
    font-size: var(--font-med-l);
    line-height: 2.2rem;
    max-width: var(--content-width);

}



.privacy-subtitle {
    margin-bottom: 0rem;
    font-size: var(--font-med-l);
}

.privacy-list {
    margin-top: 0rem;
}

.privacy-text {
    margin-top: 0rem;
}


.privacy-address {
    margin-top: 1rem;
}
