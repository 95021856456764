@import url('variables.css');

.FeedContainer {
  display: flex;
  flex-direction: column;
  max-width: var(--content-width);
  margin: 0 auto; /* Center-align the container */
  padding: 0 3rem;
}

.FeedSubHeader {
  display: flex;
  align-items: center;
  background-color: var(--main-color);
  justify-content: space-between;
}

.FeedPostHeaderH2 {
  text-align: left;
  font-size: var(--font-lg);
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.FeedNavItems {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  margin: 2rem 0;
}

.FeedNavButton {
  background: none;
  border: none;
  font-size: var(--font-med-xl);
  cursor: pointer;
  color: var(--text-color);
  position: relative;
  text-decoration: none;
  padding: 0;
}

.FeedNavButtonActive {
  color: var(--nav-color-active);
  font-family: 'inter Display Bold';
}

.FeedNavButtonActive::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.25rem;
  background-color: var(--nav-color-active);
  position: absolute;
  bottom: -2.25rem;
  left: 0;
}

.FeedMainContent {
  display: flex;
  max-width: 100%;
  margin-top: 2rem;
}

.FeedMatchingKeywords {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow: auto;
  padding: 0 3rem;
  justify-content: flex-start;
  align-items: center;
}

.FeedSidebar {
  width: 19.58rem;
  padding: 1rem;
  border-right: var(--border);
}

.FeedSortBar {
  display: flex;
  align-items: flex-start;
  height: 5rem;
  padding: 2rem 0;
  padding-bottom: 0;
  gap: 2rem;
  height: fit-content;
  flex-wrap: wrap;
}

.FeedSortBarWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.FeedSortFilters,
.FeedSortItems {
  display: flex;
  align-items: center;
}

.FeedSortItems {
  gap: 3rem;
}

.KeywordSearchInput {
  margin-left: auto; /* Push the input field to the end */
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: var(--text-color);
  width: 250px; /* Set desired width */
  padding: 1rem 2rem;
  background-color: var(--background-color);
  border: none;
  font-size: var(--font-med-s);
  cursor: pointer;
}

.css-1131c6q-control,
.css-h688qz-control {
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.FeedSortButton {
  margin-right: 1rem; /* Add spacing between dropdowns */
}

.PostButton {
  width: 16.916rem;
  height: 3.083rem;
  background: linear-gradient(90deg, #1dcf5b 0%, #59ff93 100%);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: var(--font-med-xl);
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
.PostButton:hover {
  background: linear-gradient(90deg, #1dcf5b 20%, #59ff93 80%);
}
.SidebarList {
  list-style-type: none;
  padding: 0;
  font-family: 'Inter Display Bold';
  font-size: 1.417rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.SidebarList li {
  cursor: pointer;
  position: relative;
  text-align: center;
  width: 14.5rem;
  height: 3.583rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.SidebarList li.SidebarActive {
  background-color: #e6ffec;
  font-family: 'Inter Display Bold';
  text-align: center;
  border-radius: 5px;
  color: rgb(50, 205, 50);
}

.SidebarListSpan {
  margin-right: auto;
}

.SidebarList hr {
  margin: 1rem 0;
  width: 14.5rem;
  padding: 0 1rem;
  background-color: #ccc;
}

.FeedContent {
  overflow-y: visible;
  width: calc(65% - 2rem);
  border-right: var(--border);
  padding-right: 2rem;
}

.FeedKeywordSearch {
  width: var(--main-padding);
  height: 3rem;
  padding: 0 1rem;
  border: none;
  background-color: transparent;
  border-radius: 2rem;
  border: 0.1rem solid #f2f2f2;
  font-size: var(--font-med-s);
}

.FeedKeyword {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  margin-left: 1rem;
}

.FeedKeywordSearch::placeholder {
  font-size: var(--font-med-s);
  color: var(--placeholder-color);
  text-align: center;
}

.FeedKeywordButton {
  background-color: #f2f2f2;
  border: none;
  font-size: var(--font-med-s);
  padding: 0 2rem;
  height: 3rem;
  display: flex;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
  font-family: 'Inter Display Bold', sans-serif !important;
}

.FeedSentiment,
.FeedCategory,
.FeedPostType {
  max-width: 35rem;
}

.Quick {
  max-width: 40%;
}

.FeedKeywordButton {
  background-color: transparent;
  color: var(--text-color);
}

.FeedSortText {
  font-size: var(--font-med-s);
  color: var(--action-grey);
}

.FeedKeywordReset {
  border: none;
  padding: 1rem;
  height: 3rem;
  background-color: var(--background-color);
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: var(--font-med-s);
  color: var(--text-color);
  padding: 0 2rem;
  font-family: 'Inter Display Bold', sans-serif !important;
}

.SidebarHrMain {
  width: calc(100% + 2rem);
  margin-left: -1rem;
}

.SidebarHrBookmark {
  width: calc(100% + 0rem) !important;
  margin-left: -1rem;
}

.FeedBold {
  font-family: 'Inter Display Bold';
}

.TopBar {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  justify-content: left;
  gap: 1rem;
  color: var(--text-color);
}

.TopBarButton {
  background: none;
  border: none;
  font-size: var(--font-med-l);
  cursor: pointer;
  color: var(--text-color);
  display: flex;
  align-items: center;
}

.TopBarRow {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-left: 1rem;
}

.TopBarArrow {
  margin-left: 0.5rem;
}

.RightSidebar {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  width: calc(35% - 2rem);
}

.SponsoredHeader {
  color: #ccc;

  font-size: var(--font-med-s);
}

.Sponsor {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.RightSidebarH2 {
  font-size: var(--font-med-xl);
  margin-top: 0rem;
}

.TrendingTopics,
.Sponsor,
.TrendingStocks,
.TrendingCryptocurrencies {
  text-align: left;
}

.h2-feed {
  font-size: var(--font-med-xl);
  margin-bottom: 1rem;
}

.Topic,
.Stock {
  margin-bottom: 1rem;
}

.TypeOfPost,
.FeedCategory,
.Bookmarked {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Topic h3 {
  font-size: var(--font-med-xs);
  font-family: 'Inter Display Bold';
}

.Topic p,
.Topic span {
  color: #777;
}

.Sponsor img {
  width: 100%;
}

.SponsorText {
  font-size: var(--font-med-s);
  line-height: 2.2rem;
  margin: 0;
}
.Sponsor h2 {
  margin-top: 0rem;
}

.SponsorTextSmall {
  font-size: var(--font-med-xs);
}
.Stock {
  display: flex;
  justify-content: space-between;
}

.Positive {
  color: #32cd32;
}

.Negative {
  color: #f44336;
}

.Stock div {
  flex: 1;
  text-align: left;
}

.Stock div:nth-child(2),
.Stock div:nth-child(3) {
  text-align: center;
}

.KeywordForm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.KeywordForm input::placeholder {
  font-size: 1.3rem;
  color: var(--placeholder-color);
}

.KeywordInput {
  font-size: 1.3rem;
  background-color: #f0f0f0;
  border-radius: 0.4166rem;
  border: none;
  height: 3.583rem;
  padding: 0 1rem;
  width: 14.5rem;
}

.KeywordInput::placeholder {
  font-size: 1.3rem;
  color: var(--placeholder-color);
}

.SideNameSpan {
  margin-left: 1rem;
}

.Keywords {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 0 !important;
  width: 16.5rem !important;
}

.Keyword {
  background-color: #f2f2f2;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.25rem;
  position: relative;
  font-size: var(--font-med-s);
  padding: 0rem 2rem;
  height: 3rem;
  overflow-x: visible;
  overflow-y: visible;
}

.EmptyKeywords {
  font-size: 1.1rem;
  color: #707070;
  display: flex;
  justify-content: center;
  align-items: center;
}

.keyword-x {
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--background-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.05rem solid var(--text-color);
  top: -0.5rem;
  right: -0.5rem;
  cursor: pointer;
  color: var(--text-color);
  font-size: var(--font-med-xs);
}

.RemoveKeyword {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  cursor: pointer;
}

.TrendingAccounts {
  background-color: var(--main-color);
  text-align: left;
}

.TrendingAccounts hr {
  margin: 0.5rem 0;
  border: none;
  border-top: var(--border);
}
.TrendingAccounts ul.account-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* 4 columns */
  padding: 0;
  list-style: none;
  margin: 0;
}

ul.account-grid li:last-child {
  margin-bottom: 0;
}

.transBorder {
  border: var(--border) !important;
  background-color: transparent !important;
}

.account-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  gap: 1rem;
  margin-bottom: 2rem;
  z-index: var(--z-index-low);
}

.TrendingUserResult {
  cursor: pointer;
  border-bottom: var(--border);
  padding: 0.5rem;
  background-color: var(--background-color);
}

.trending-account-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  flex: 1; /* This will make the info div take up remaining space */
  display: flex;
  flex-direction: column;
}

.trending-account-info span {
  font-size: var(--font-med-s);
}

.TrendingUserResult:hover {
  background-color: var(--hover-color);
}

.TrendingAccounts .rank {
  font-size: var(--font-med-s);
}

.TrendingAccounts .name {
  font-family: 'Inter Display Bold';
  flex-grow: 1;
}

.TrendingAccounts .followers {
  margin-left: auto;
}

.TrendingAccounts .highlight .name {
  color: #ffcc00;
}

.TrendingAccounts .header-row {
  display: flex;
  align-items: center;
  font-family: 'Inter Display Bold';
  margin-bottom: 0.5rem;
  font-size: var(--font-med-xs);
}

.TrendingAccounts .header-row .profile-pic-placeholder {
  max-width: 1.4rem;
  max-height: 1.4rem;
  margin-right: 0.5rem;
}

.profile-pic {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  transition: transform 0.2s ease;
}

.TrendingAccounts .header-row .name-header {
  flex-grow: 1;
  margin-left: 2.75rem;
  font-size: 1.1rem;
  color: var(--action-grey);
  font-family: 'Inter Display Bold';
}

.name-header,
.followers-header {
  padding: 0.5rem 0;
}

.name,
.rank,
.followers {
  font-size: var(--font-med-s);
}

.TrendingAccounts .header-row .followers-header {
  color: #b3b3b3;
  margin-left: auto;
}

.account-info {
  display: flex;
  flex-direction: column;
}

.CreatePostFeed {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 15rem;
  max-height: 40rem;
  flex-wrap: wrap;
}

.CreatePostFeed h2 {
  margin: 0;
}
.CreatePostFeedButtons {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
}

.CreatePostFeedButton {
  color: var(--text-color);
  border: none;
  cursor: pointer;
  font-size: var(--font-med-s);
  background-color: var(--background-color);
  padding: 1rem 2rem;
  border-radius: 2rem;
  max-height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter Display Bold', sans-serif !important;
}

.CreatePostFeedBtn {
  color: var(--text-color);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: var(--font-med-s);
  background-color: var(--background-color-feed-btn);
  padding: 1rem 2.5rem;
  border-radius: 2rem;
  max-height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter Display Bold', sans-serif !important;
}

.CreatePostFeedInput {
  width: calc(100% - 4rem);
  min-height: 10rem;
  max-height: 20rem;
  resize: none;
  overflow: auto;
  padding: 2rem;
  border: var(--border);
  background-color: var(--background-color-input);
  border-radius: 5px;
  font-size: var(--font-med-s);
  color: var(--text-color);
}

.CreatePostFeedInput::placeholder {
  font-size: var(--font-med-s);
  color: var(--placeholder-color);
}

.FeaturedPosts {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.featured-post-item {
  text-align: left;
  font-size: var(--font-med-s);
  border-bottom: var(--border);
  cursor: pointer;
}

.featured-post-author {
  color: #c2c2c2;
}

.FeedNavActions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.FeedNavActionButton {
  background-color: #f2f2f2;
  border: none;
  border-radius: 2rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3rem;
  max-height: 3rem;
  overflow: auto;
  cursor: pointer;
  font-size: var(--font-med-s);
}
.FeedSearchBar {
  width: 100%;
  color: var(--text-color);
  font-size: 1.85rem;
  border: none;
  overflow: visible;
  background-color: transparent;
}

.FeedSearchBarKeywordContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  overflow: visible;
}

.FeedSearchBarKeyword {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 2rem;
  font-size: var(--font-med-s);
  padding: 0.75rem 2rem;
  position: relative;
  border: var(--border);
}

.FeedSearchBar::placeholder {
  font-size: 1.85rem;
  color: var(--placeholder-color);
}

.FeedSearchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  background-color: var(--background-color);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  height: 3rem;
  overflow-y: visible;
  overflow-x: auto;
}

.FeedInputBar::placeholder {
  font-size: var(--font-med-l);
  color: var(--input-text-color);
  font-family: 'Inter Display Bold';
}

.FeedInputBar {
  width: 100%;
  color: var(--text-color);
  font-size: var(--font-med-l);
  border: none;
  overflow: visible;
  background-color: transparent;
  padding: 0;
  resize: none;
  line-height: 2.2rem;
}

.FeedInputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  background-color: var(--background-color);
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;
  height: auto;
  overflow: auto;
  min-height: 3rem;
  flex-direction: column;
  padding: 2rem 2rem;
}

.FeedTradesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.FeedTradesContainer > .PostTradeContainer:first-child {
  margin-top: 0rem;
}

.FeedWelcome-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: var(--text-color);
  padding: 4rem 0;
  border-bottom: var(--border);
}

.FeedWelcome-content {
  margin-right: 2rem;
  text-align: left;
  width: 65%;
}

.FeedWelcome-title {
  font-size: var(--font-xl-s);
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 0rem;
}

.FeedWelcome-description {
  font-size: var(--font-lg);
  line-height: 3rem;
  margin: 0;
}

.FeedWelcome-highlight {
  color: var(--stock-change-pos);
}
.FeedWelcome-create-account-btn {
  font-size: var(--font-med-xl);
  transition: transform 0.2s ease;
}

.FeedWelcome-create-account-btn:hover {
  transform: scale(1.1);
}

.FeedAddTransaction {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  cursor: pointer;
}

.FeedAddTransactionBtn {
  width: 100%;
  padding: 1rem 2rem;
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 0.5rem;
  border: none;
  font-size: var(--font-med-s);
  cursor: pointer;
}

.FeedTransactionModal {
  position: absolute;
  z-index: var(--z-index-high);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  background-color: var(--main-color);
  border: var(--border);
  padding: 2.5rem;
  box-sizing: border-box;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 2rem;
}

.FeedTransactionHeader {
  margin: 0;
}

.Feed-toggle-container {
  display: flex;
  border: var(--border);
  border-radius: 5rem;
  overflow: hidden; /* Prevents button overflow */
  background-color: transparent; /* Default background color */
  width: 75%;
}

.Feed-toggle-button {
  flex: 1; /* Equal width for all buttons */
  padding: 1rem 2rem;
  color: var(--text-color);
  background: transparent; /* Default button background */
  border: none; /* Remove border around buttons */
  cursor: pointer;
  font-size: var(--font-med-l);
  white-space: nowrap;
}

.Feed-toggle-button.Feed-active {
  background-color: var(
    --stock-change-pos
  ); /* Bright green for active button */
  color: #000;
  border-radius: 5rem;
}

.FeedPortfolioSelect {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.FeedPortfolioSpan {
  font-size: var(--font-med-s);
  color: var(--action-grey);
}

.FeedInputContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--main-color);
  border-radius: 0.5rem;
  color: var(--text-color);
}

.FeedInputRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-bottom: 0.05rem solid var(--border-color);
  padding-bottom: 1rem;
}

.FeedLabel {
  font-size: var(--font-med-l);
  color: var(--text-color);
  flex: 1;
  font-family: 'Inter Display Bold', 'sans-serif';
}

.FeedInputField {
  flex: 1;
  background-color: var(--background-color-input);
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  font-size: var(--font-med-l);
  color: var(--text-color);
  text-align: left;
  border: var(--border);
}

.FeedInputField::placeholder {
  color: var(--placeholder-color);
  font-family: 'Inter Display Bold', 'sans-serif';
}

.FeedToggleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Feed-buy-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.FeedShareTransaction {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.FeedFilterWrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.FeedFiltersModal {
  position: absolute;
  top: calc(100% + 1rem); /* Position it below the button */
  left: 50%; /* Center it horizontally relative to the parent */
  transform: translateX(-50%); /* Shift it back by half its width */
  z-index: var(--z-index-high);
  background-color: var(--main-color);
  border: var(--border);
  box-sizing: border-box;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 2.5rem;
  width: 42.5rem;
}
.FeedFiltersModal::before {
  content: '';
  position: absolute;
  left: 50%; /* Start the caret in the center of the modal */
  top: -1.4rem; /* Position the caret above the modal */
  transform: translateX(
    -50%
  ); /* Shift it back by half its own width to center */
  border-width: 0.75rem; /* Size of the caret */
  border-style: solid;
  border-color: transparent transparent var(--border-color) transparent;
}

.FeedFiltersOptionsRow {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.FeedFiltersModalSearch {
  width: 100%;
  background-color: var(--background-color);
}

.FeedFiltersModalSearch {
  width: 100%;
  background-color: var(--background-color);
  border-radius: 0.5rem;
  border: var(--border);
  padding: 1rem;
  font-size: var(--font-med-l);
  color: var(--text-color);
  box-sizing: border-box;
  border: none;
}

.FeedFiltersModalSearch::placeholder {
  font-family: 'Inter Display Bold', sans-serif;
}

.FeedFiltersKeywordsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.FeedFilterButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--font-med-s);
  color: var(--text-color);
  font-family: 'Inter Display Bold', sans-serif;
  padding: 0;
}

.FeedFiltersTitle {
  font-size: var(--font-med-l);
  font-family: 'Inter Display Bold', sans-serif;
  margin: 0;
  margin-bottom: 1rem;
}

.FeedFiltersSection {
  margin: 2rem 0rem;
}

.FeedNoKeywords {
  font-size: var(--font-med-s);
  color: var(--action-grey);
}

/* Backdrop styles for the blurred effect */
.FeedBackdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
  backdrop-filter: blur(1px); /* Apply blur */
  z-index: 10; /* Ensure it is below the modal */
  display: none; /* Initially hidden */
}

.FeedBackdrop.active {
  display: block; /* Show when active */
}

/* Mobile-specific styles */
@media screen and (max-width: 768px) {
  .CreatePostFeedButton {
    font-size: var(--font-xl);
  }

  .FeedContent {
    max-width: 100%; /* Prevent elements from exceeding the screen width */
    overflow-x: hidden; /* Prevent overflow content */
    box-sizing: border-box; /* Include padding and borders in the element's width */
  }

  .FeedContent.active,
  .RightSidebar.active {
    display: block; /* Show only the active section */
  }
  /* Hiding unwanted sections in mobile */
  .FeedContent,
  .FeedSidebar,
  .FeedWelcome-container {
    display: none;
  }
  .FeedPostHeaderH2 {
    font-size: 22px;
  }
  /* FeedContainer to show only Trades */
  .FeedContainer {
    flex-direction: column;
    max-width: 100%;
    padding: 0;
    padding-bottom: 75px;
  }

  .FooterContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    z-index: 1000;
  }

  .FooterItem {
    color: gray;
    text-align: center;
    flex: 1;
    font-size: 12px;
  }

  .FooterIcon {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .FooterItem.active {
    color: white; /* Highlight active button */
  }

  /*Trades container to occupy full width */
  .RightSidebar {
    width: 100%;
    padding: 1rem;
  }

  /* Make trades look more compact */
  .FeedTradesContainer {
    gap: 0.5rem;
  }

  /* Hide 'Add Transaction' button */
  .FeedAddTransaction {
    display: none;
  }

  /* Increase the height of trade cards */
  .PostTradeContainer {
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    border: 1px solid var(--border-color);
    border-radius: 1rem;
    background-color: var(--main-color);
  }

  /*To Align trade card content vertically */
  .PostTradeContainer > * {
    margin: 0;
  }

  .PostTradeContainer p,
  .PostTradeContainer span {
    font-size: var(--font-med-l);
  }

  .FeedTradesContainer {
    padding: 1rem 0;
    margin: 0 1rem;
  }

  /* Center-align trades tab buttons */
  .FeedNavItems {
    justify-content: flex-start;
    height: 35px;
    gap: 2rem;
    height: 35px;
  }
  .FeedNavItems button {
    max-height: 35px;
    width: 80px;
    border-radius: 30px;
    font-size: 12px;
  }

  .FeedSortItems div {
    height: 35px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }

  .css-kacf8m-menu .css-no1ryp {
    height: 70px !important;
  }

  .FeedSortButton:nth-child(1) {
    margin-right: 0;
  }

  /*Heading size for Trades */
  .RightSidebarH2 {
    font-size: var(--font-med-l);
    text-align: center;
    margin-bottom: 1rem;
  }

  /* Ensure each Select element is vertically aligned */
  .FeedSortButton,
  .FeedPostType {
    height: 30px; /* Match height of other elements */
    font-size: 12px;
    display: flex;
    align-items: center; /* Vertically center the text */
    justify-content: center;
    padding: 0.5rem;
    border-radius: 8px; /* Optional: To match button styles */
  }

  /* Trades section layout */
  .FeedTradesContainer > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .FeedAddTransactionBtn,
  .cp-AddElementButton {
    width: 100%;
    font-size: var(--font-med-s);
  }

  .FeedTradesContainer button {
    display: block;
    margin: 0 auto;
  }

  /* Hide all other sections */
  .FeedContent,
  .FeedSidebar,
  .FeedWelcome-container {
    display: none;
  }

  .FeedContent#feedContent {
    display: block; /* Display Posts section */
    width: 100%;
    padding: 1rem;
  }

  /* .FeedInputWrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  } */

  .FeedInputWrapper {
    display: none;
  }

  .FeedInputBar {
    width: 100%;
    height: 80px;
    border-radius: 10px;
    font-size: 14px;
    padding: 0.5rem;
    resize: none;
  }

  .CreatePostFeedBtn {
    font-size: 12px;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    align-self: flex-end;
  }

  /* Posts list styling */
  .Post {
    margin-bottom: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    padding: 1rem;
    background-color: var(--main-color);
    font-size: 14px;
  }

  .Post p,
  .Post span {
    font-size: 14px;
  }

  /* Featured Posts section */
  .featured-posts-list {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .featured-post-item {
    padding: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--secondary-color);
    font-size: 14px;
    cursor: pointer;
  }

  .featured-post-title {
    font-weight: bold;
  }

  .featured-post-author {
    font-size: 12px;
    color: var(--text-muted-color);
  }

  /* Sort and Filter buttons */
  .FeedSortBarWrapper {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 0;
  }

  .FeedSortButton,
  .FeedFilterButton {
    height: 35px;
    font-size: 12px;
    padding: 0.5rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* View more button */
  .cp-AddElementButton {
    width: 100%;
    font-size: 14px;
    margin-top: 1rem;
    justify-content: center;
  }

  .PostContainerWrapper {
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: var(--main-color);
  }

  /* ProfilePicContainer */
  .ProfilePicContainer {
    width: 35px;
    height: 35px;
    border-radius: 50%; /* Maintain circular shape */
    overflow: hidden;
    margin-right: 1rem;
  }

  .ProfilePicContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* PostHeader */
  .PostHeader {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 17px; /* Increased font size */
  }

  .PostHeader .authorName {
    font-weight: bold;
    font-size: 18px; /* Increased size */
  }

  .PostHeader .timeStamp {
    font-size: 14px;
    color: var(--text-muted-color);
  }

  .PostContent .PostContentP {
    font-size: 13px;
    overflow: hidden; /* Turn off scroll */
  }

  /* PostActions */
  .PostActions {
    display: none; /* hide PostActions */
    justify-content: space-around;
    align-items: center;
    opacity: 0.5;
    pointer-events: none; /* Disable interactions */
    font-size: 14px;
  }

  .PostActions button {
    cursor: not-allowed; /* Indicate disabled actions */
  }

  .PostContent {
    font-size: 15px;
    line-height: 1.5;
    margin-top: 1rem;
  }
  .FeedFilterButton {
    display: none;
  }

  .FeedLoadingSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
  }
  .FeedMainContent {
    margin-top: 0px;
  }
  .transBorder {
    border: 1px solid white !important;
    color: white !important;
  }
}
