@import url('variables.css');

  
  .pv-PostViewContainer {
    max-width:var(--content-width); 
    margin: 0 auto; /* Center-align the container */
    padding: 2rem 3rem;
    text-align: left;
  }


  .pv-BackToFeedContainer {
    margin-bottom: 1rem;
  }
  
  .pv-BackButton {
    background: none;
    border: none;
    color: var(--action-grey);
    font-size: var(--font-med-l); 
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1rem 0;

  }

  
  
  .pv-BackButton .fa-arrow-left {
    margin-right: 0.5rem; 

  }
  .pv-ReposterText {
    color: #707070;
    font-size: 1.3rem; 
    margin: 1rem 0 1rem 0;

  }

  .pv-PostActions {
    border-bottom: var(--border);
    border-top: var(--border);
    padding-top: 1.5rem; 
    padding-bottom: 1.5rem;
  }

  
  
  .pv-PostViewContainer h1 {
    margin-bottom: 0.83rem; 
    margin-top: 0.83rem;
  }
  
  .pv-PostAuthor {
    display: flex;
    align-items: center;
    margin-bottom: 1.67rem; 
    cursor: pointer;
  }

  .pv-author {
    font-family: 'SF Pro Display Bold';
    font-size: var(--font-med-l);
    line-height: 2rem;
  }

  .pv-PostTitle {
    font-size:3.1rem;
  }

  .pv-username {
    font-size: var(--font-med-l);
    line-height:2rem;
  }
  
  .pv-ProfilePic {
    width: 4.17rem;
    height: 4.17rem; 
    border-radius: 50%;
    margin-right: 0.83rem; 
  }
  
  .pv-AuthorDetails {
    display: flex;
    flex-direction: column;
  }
  
  .pv-Verified {
    color: #1DCF5B;
    margin-left: 0.42rem; 
  }
  
  .pv-PostContent p {
    font-size: var(--font-med-l); 
    line-height: 2.2rem;
  }

  

  .pv-PostContent a {
    color: #1DCF5B;
  }

  .pv-PostCaption {
    font-size: 1.30rem;
    line-height: 2.2rem;
    color: #707070;
    margin: 0;
  }
  
  .pv-PostImage {
    max-width: 100%;
    object-fit: contain;
  }

  .pv-PostTopicLine {
    color: #707070;
    font-size: var(--font-med-l);
  }
  
  .pv-PostStats {
    display: flex;
    justify-content: space-around;
    margin-top: 1.67rem; 
    font-size: var(--font-sm); 
  }
  
  .pv-CommentsSection {
    margin-top: 2.5rem; 
  }
  
  .pv-Comment {
    margin-bottom: 1.67rem; 
  }
  
  .pv-CommentAuthor {
    display: flex;
    align-items: center;
    margin-bottom: 0.83rem;
  }
  
  .pv-CommentProfilePic {
    width: 3.33rem; 
    height: 3.33rem;
    border-radius: 50%;
    margin-right: 0.83rem; 
  }
  
  .pv-CommentAuthorDetails {
    display: flex;
    flex-direction: column;
  }
  
  .pv-CommentContent p {
    font-size: var(--font-sm); 
    line-height: 1.5;
  }


  .pv-PostViewDate {
    color: #707070;
    font-size: 1.30rem;
    margin-top: 1.67rem; 
    margin-bottom: 1.67rem;
  }

  .pv-CommentsText {
    font-size: 1.55rem;
    font-family: 'SF Pro Display Bold';
    margin-bottom: 1.67rem; 
  }

  .pv-PostContentP {
    white-space: pre-wrap;
    font-size: var(--font-med-l);
    margin:2rem 0rem;
    line-height: 2.2rem;
  }
  .pv-PostContentP a {
    color: #1DCF5B;
  }

  .pv-CommentsInput {
    width: calc(100% - 6rem);
    padding:0 4rem 0 2rem;
    border: none;
    height: 5.33rem;
    background-color: var(--background-color);
    border-radius: 0.5rem;
    font-size: var(--font-med-l);
    color: var(--text-color);
  }

  .pv-CommentsInputWrapper {
    position: relative;
  }

  .pv-CommentsInputSubmit {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0.75rem;
    top: 1.15rem;
    width: 3rem;
    height:3rem;
    border: none;
    color: white;
    font-size: var(--font-med-l);
    cursor: pointer;
    background-color: var(--stock-change-pos);
    border-radius: 50%;
  }


  .pv-CommentsInput::placeholder {
    font-size: var(--font-med-l);
    color: var(--placeholder-color);
  }

  .pv-VoteWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }