/* Default (Light) Theme */
:root {
  --font-sm-lite: 0.813rem;
  --font-sm: 1rem;
  --font-med-xs: 1.2rem;
  --font-med-s: 1.25rem;
  --font-med-m: 1.3rem;
  --font-med-l: 1.4rem;
  --font-med-xl: 1.5rem;
  --font-lg: 2rem;
  --font-xl: 2.5rem;
  --font-xl-s: 3rem;
  --font-xl-m: 4rem;
  --font-xl-l: 5rem;

  /*General colors*/
  --background-color: #f2f2f2;
  --background-color-feed-btn: #e6e6e6;

  --background-color-btn: #f2f2f2;
  --text-color: #000000;
  --invert-text-color: #ffffff;
  --input-text-color: #868885;
  --text-color-rgb: 0, 0, 0;
  --text-color-input: #c6c6c6;
  --border-color: #c6c6c6;
  --background-color-input: rgba(255, 255, 255, 0.05);
  --main-color: #fff;
  --main-green: #1dcf5b;
  --ov-cont-color: #f0f0f0;
  --ma-green: #28a745;
  --ma-red: #dc3545;
  --action-grey: #707070;
  /*Navigation*/
  --nav-color-active: #1dcf5b;

  --z-index-low: 10;
  --z-index-med: 20;
  --z-index-high: 30;
  --main-padding: max(3rem, calc(5% + 12rem));

  /*Border*/
  --border: 0.05rem solid var(--border-color);

  /*Option table*/
  --table-odd-row-bg: #f2f2f2; /* Light gray for odd rows */
  --table-even-row-bg: #fff; /* Light gray for even rows */
  --table-hover-bg: #e0e0e0;

  /*Follow button*/
  --follow-btn-color: #fff;
  --follow-btn-plus-color: #1dcf5b;

  /*Stocks*/
  --stock-filter-btn-color: #000;
  --stock-filter-active-text: #fff;

  --stock-change-pos: #1dcf5b;
  --stock-change-neg: #ff6347;

  /* Hover */
  --hover-color: #f0f0f0;

  --placeholder-color: #c6c6c6;

  --settings-bg: #f2f2f2;

  --content-width: clamp(
      1080px,  /* your minimum content width */
      calc(100vw - 2 * var(--main-padding)), 
      1440px   /* your maximum content width */
);
  --stock-chart-height: 35rem;
}

/* Dark Theme */
[data-theme='dark'] {
  /*General colors*/
  --background-color: #111510;
  --background-color-feed-btn: #1d211c;
  --main-color: #030902;

  --background-color-btn: #353a36;
  --background-color-input: rgba(255, 255, 255, 0.05);
  --text-color: #ffffff;
  --invert-text-color: #000000;
  --text-color-rgb: 255, 255, 255;
  --text-color-input: #ffffff;
  --border-color: #353a36;
  --ov-cont-color: #353a36;
  --ma-green: #00ff00;
  --ma-red: #ff0000;

  /*Navigation*/
  --nav-color-active: #00ff00;

  /*Border*/
  --border: 0.05rem solid var(--border-color);

  /*Option table*/
  --table-odd-row-bg: #353a36; /* Darker gray for odd rows */
  --table-even-row-bg: #030902; /* Default dark background for even rows */
  --table-hover-bg: #4a4a4a; /* Medium-dark gray for hover */

  /*Follow button*/
  --follow-btn-color: #1dcf5b;
  --follow-btn-plus-color: #fff;

  /*Stock filter button*/
  --stock-filter-btn-color: #00ff00;
  --stock-filter-active-text: #000;

  --stock-change-pos: #00ff00;
  --stock-change-neg: #ff0000;

  --settings-bg: #101510;

  /* Hover */
  --hover-color: #4a4a4a;
  --placeholder-color: #c6c6c6;
}
