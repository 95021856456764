@import url('variables.css');

.signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto; /* Center-align the container */
  padding: 0 3rem;
}

.si-main-content {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
}

.si-main-content {
  max-width: 50%;
  min-width: 50%;
  text-align: left;
}

.si-auth-content {
  max-width: 50%;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-left: 3rem;
  padding-top: 0rem;
  box-sizing: border-box;
}

.si-intro-text {
  border-right: var(--border);
  padding: 1rem;
  padding-right: 3rem;
  padding-top: 0rem;
  box-sizing: border-box;
}

.si-intro-text h2 {
  border-bottom: var(--border);
  margin: 0;
  padding-bottom: 1.5rem;
  font-size: var(--font-lg);
}

.si-intro-text p {
  font-size: var(--font-med-xl);
  margin: 0;
  padding: 1rem 0;
  color: var(--text-color);
  line-height: 1.5;
}

.SignNavItems {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: var(--border);
}

.SignNavButton {
  background: none;
  border: none;
  font-size: var(--font-lg);
  cursor: pointer;
  color: var(--border-color);
  position: relative;
  text-decoration: none;
  padding: 0;
  padding-bottom: 1.5rem;
}

.SignNavButtonActive {
  color: var(--nav-color-active);
  font-family: 'SF Pro Display Bold';
}

.SignNavButtonActive::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.25rem;
  background-color: var(--nav-color-active);
  position: absolute;
  bottom: -0.12rem;
  left: 0;
}

.si-link {
  text-decoration: none;
  color: var(--stock-change-pos);
}

.si-main-header {
  font-size: var(--font-xl-m);
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.si-main-content {
  display: flex;
  width: 100%; /* Full width of the parent container */
  padding: 2rem;
  padding-top: 0rem;
}

.si-auth-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding-top: 3rem;
}

.si-auth-form label {
  font-size: var(--font-med-l);
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.si-auth-form input {
  padding: 1rem;
  border: var(--border);
  border-radius: 0.25rem;
  font-size: var(--font-med-xl);
  background-color: transparent;
  color: var(--text-color);
  text-align: center;
}

.si-auth-form input::placeholder {
  color: var(--border-color);
  text-align: center;
}

.si-auth-form-wrapper h3 {
  color: var(--text-color);
  font-size: var(--font-lg);
  margin-bottom: 1rem;
}

.pin-inputs {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.pin-input {
  width: 3rem;
  height: 4rem;
  font-size: var(--font-lg);
  text-align: center;
  border: var(--border);
  border-radius: 0.5rem;
  background-color: transparent;
  color: var(--text-color);
}

.pin-input:focus {
  outline: none;
  border-color: var(--text-color);
}

.disclaimer-container-signup,
.terms-container-signup {
  text-align: left;
  font-size: var(--font-med-xl);
  color: var(--action-grey);
}

.disclaimer-label,
.terms-label {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
}

.terms-link {
  font-size: var(--font-med-l);
}

.disclaimer-label {
  margin-bottom: 2rem;
}

.pin-header-label {
  font-size: var(--font-med-xl);
  color: var(--text-color);
}

.checkbox-input {
  accent-color: var(--stock-change-pos);
}

.si-form-btn {
  width: fit-content;
  margin-top: 2rem;
  align-self: center;
  font-size: var(--font-med-xl);
}

.createPfpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.createPfpOptions {
  display: flex;
  gap: 2rem;
}

.createPfpOption {
  width: 8rem;
  height: 8rem;
  cursor: pointer;
  border: 0.25rem solid var(--border-color);
  border-radius: 50%;
  object-fit: cover;
  transition: border 0.2s;
}

.createPfpOption:hover {
  border-color: var(--action-grey);
}

.createPfpOption.selected {
  border: 0.25rem solid var(--stock-change-pos);
}

.flex-start {
  align-items: flex-start;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .signin-container {
    height: 70vh; /* Full height */
    margin: auto 0;
    padding: 0 2rem; /* Less padding on mobile */
  }

  .si-main-header {
    font-size: var(--font-xl);
    margin-top: 4rem; /* Adjusted margin */
    margin-bottom: 2rem; /* Adjusted margin */
  }

  .si-main-content {
    display: block; /* Stack elements vertically */
    max-width: 100%; /* Full width */
    padding: 0; /* Remove padding */
    margin: 0;
  }

  .si-auth-content {
    margin: 0 auto;
    padding: 1rem;
    min-width: 100% !important;
    max-width: 100% !important; /* Full width */
  }

  .si-mobile-buttons {
    display: flex;
    width: 100%; /* Full width */
    flex-direction: column;
    gap: 1rem; /* Adjusted gap */
  }
  .si-mobile-buttons button {
    width: 100%; /* Full width */
    border-radius: 5px !important;
    font-size: var(--font-med-l);
  }

  .si-intro-text {
    padding: 0;
    padding-right: 0;
    margin-bottom: 2rem; /* Adjusted margin */
    border: none; /* Remove border */
  }

  .si-intro-text h2 {
    font-size: var(--font-med-xl); /* Slightly smaller header font */
    margin: 0;
    padding-bottom: 1rem;
  }

  .si-intro-text p {
    font-size: var(--font-med-l);
    padding: 0.5rem 0;
    line-height: 1.4;
  }

  .SignNavItems {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center;
  }

  .SignNavButton {
    font-size: var(--font-lg);
    padding: 1rem; /* More padding for better touch experience */
    width: 100%; /* Full width for mobile */
  }

  .si-auth-form input {
    width: 100%; /* Full width for inputs */
    font-size: var(--font-med); /* Smaller font size */
    text-align: center;
    padding: 1rem;
  }

  .pin-inputs {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .pin-input {
    width: 3rem;
    height: 3rem; /* Reduced height for mobile */
    font-size: var(--font-med); /* Smaller font size */
  }

  .disclaimer-container-signup,
  .terms-container-signup {
    font-size: var(--font-med); /* Smaller font size */
  }

  .terms-label,
  .disclaimer-label {
    align-items: end !important;
    gap: 2rem; /* Less gap */
  }

  .checkbox-input {
    width: auto;
  }

  .si-form-btn {
    width: 100%; /* Full width button */
    font-size: var(--font-med); /* Smaller font size */
  }

  .createPfpContainer {
    gap: 2rem; /* Adjust gap */
  }

  .createPfpOptions {
    gap: 1.5rem;
    justify-content: center; /* Center the options */
  }

  .createPfpOption {
    width: 6rem;
    height: 6rem; /* Smaller profile picture options */
  }

  .si-auth-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;
  }

  .si-form-header {
    font-size: var(--font-lg);
    color: var(--text-color);
    margin-bottom: 1.5rem;
  }

  .si-auth-form {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    max-width: 100%; /* Set a reasonable max width */
    box-sizing: border-box; /* Ensure the padding and borders are part of the width */
  }

  .si-auth-form input {
    min-height: 40px;
    width: 100%;
    max-width: 100%; /* Prevent inputs from exceeding form width */
    padding: 1rem;
    border: var(--border);
    border-radius: 0.25rem;
    font-size: var(--font-lg);
    background-color: transparent;
    color: var(--text-color);
    box-sizing: border-box; /* Include padding and borders in width calculation */
  }

  .pin-inputs {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }

  .pin-input {
    width: 3rem;
    height: 3rem;
    text-align: center;
    font-size: var(--font-med-s);
    border: var(--border);
    border-radius: 0.5rem;
    background-color: transparent;
    color: var(--text-color);
  }

  .forgot-pin-link {
    display: block;
    margin-top: -1rem;
    font-size: var(--font-med-l);
    color: var(--stock-change-pos);
    text-decoration: none;
  }

  .si-form-btn {
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    font-size: var(--font-med);
  }

  .outlineButton {
    background-color: transparent;
    border: var(--border);
    color: var(--text-color);
  }

  .greenButton {
    background-color: var(--stock-change-pos);
    color: var(--background-color);
  }

  /* Progress bar container */
  .progress-bar {
    width: 100%;
    height: 4px;
    background-color: var(--border-color);
    border-radius: 2px;
    margin-bottom: 1rem;
    position: relative;
  }

  /* Filled part of the progress bar */
  .progress-bar-filled {
    height: 100%;
    background-color: var(--stock-change-pos);
    border-radius: 2px;
    transition: width 0.3s ease;
  }
}
