@import url('variables.css');
.sLeadContainer {
  display: flex;
  flex-direction: column;
  max-width: var(--content-width);
  margin: 0 auto; /* Center-align the container */
  padding: 0 3rem;
  overflow: visible;
}

.sLeaderboardDetailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2.5rem;
}

.sLeaderboardHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.sLeaderboardInfo {
  flex: 1;
  width: 85%;
  padding-right: 1rem;
}

.sLeaderboardName {
  font-size: var(--font-lg);
}

.sLeaderboardUsername {
  font-size: var(--font-med-l);
  color: var(--action-grey);
}

.sLeaderboardStats {
  font-size: var(--font-med-l);
  color: var(--action-grey);
}

.sLeaderboardBio {
  font-size: var(--font-med-l);
  margin-top: 1rem;
  white-space: normal;
  word-wrap: break-word;
  max-width: 100%;
  line-height: 1.5;
}

.sLeaderboardProfileImage {
  width: 15%;
  padding-left: 1rem;
  align-self: center;
}

.sProfileImage {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  object-fit: cover;
}

.sLeaderboardActions {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-self: flex-start;
}

.sLeaderboardMainContent {
  display: flex;
  padding-top: 2.5rem;
  overflow: visible;
  border-top: var(--border);
}

.sLeaderboardLeftContent {
  width: 70%;
  padding-right: 2rem;
  border-right: var(--border);
}

.sLeaderboardRightContent {
  width: 30%;
  padding-left: 2rem;
}

.sLeaderboardLeftContentHeader {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.sLeaderboardPosition {
  color: var(--action-grey);
  font-size: var(--font-med-l);
}

.sLeaderboardSelects {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.sLeaderboardUserCardDetails {
  flex-direction: column;
  gap: 1rem;
}

.sLeaderboardUserCard {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.sLeaderboardUsersWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 2rem;
  row-gap: 2rem;
}

.sLeaderboardUserCard {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex: 1;
  background-color: var(--background-color);
  overflow: auto;
}

.sLeaderboardUserCardPosition {
  color: var(--action-grey);
  font-size: var(--font-med-s);
}

.sLeaderboardUserCardDetails {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: var(--font-med-l);
}

.sLeaderboardUserCardUsername {
  font-size: var(--font-med-l);
}

.sLeaderboardUserCardImageWrapper {
  object-fit: cover;
  width: 4rem;
  height: 4rem;
}

.sLeaderboardUserCardImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.sLargestPositionSpan {
  font-size: var(--font-med-l);
  color: var(--action-grey);
}

.sSubgroupCard {
  cursor: pointer;
  background-color: var(--background-color);
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}
