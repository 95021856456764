@import url('variables.css');

.StockContainerWrapper {
  display: flex;
  flex-direction: column;
  max-width: var(--content-width);
  margin: 0 auto; /* Center-align the container */
  padding: 0 3rem;
}

.StockContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.MainStockContainer {
  display: flex;
}

.StockPriceContent {
  width: 100%;
}

.StockGraph {
  display: flex;
  flex-direction: column;
}

.StockGraphMain {
  max-height: 50rem;
}

.StockHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.StockHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.StockInfo {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.75rem;
  margin-bottom: 2rem;
}

.StockName,
.StockPrice {
  font-size: var(--font-xl);
  font-weight: bold;
}

.StockName {
  margin-bottom: 0rem;
  margin-top: 0rem;
}
.StockPrice {
  margin-top: 0rem;
  margin-bottom: 0.25rem;
}

.StockChange {
  font-size: var(--font-med-l);
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.StockChange.positive {
  color: var(--stock-change-pos);
}

.StockChange.negative {
  color: var(--stock-change-neg);
}

.StockDuration {
  font-size: var(--font-med-l);
  color: var(--text-color);
}

.StockActions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.StockActionButton {
  background-color: black;
  color: white;
  border: none;
  padding: 1rem 2rem;
  margin-right: 1rem;
  cursor: pointer;
  border-radius: 0.5rem;
}

.StockLogo {
  margin-left: auto;
}

.StockImage {
  height: 6rem; /* 60px */
  width: 6rem; /* 60px */
  border-radius: 50%;
}

.StockOptions {
  display: flex;
  gap: 1rem;
}

.OptionButton {
  background-color: #f0f0f0;
  border: none;
  padding: 0.8rem 1.2rem; /* 8px 12px */
  border-radius: 1.5rem;
  cursor: pointer;
}

.BackButtonWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
}

.BackIcon {
  margin-right: 1rem;
}

.BackText {
  font-weight: bold;
}

.StockOptions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.OptionButton {
  background-color: #f0f0f0;
  border: none;
  padding: 0.8rem 1.2rem; /* 8px 12px */
  border-radius: 1.5rem;
  cursor: pointer;
}

.OptionButton:hover {
  background-color: #e0e0e0;
}

.StockFilters {
  display: flex;
  gap: 4rem;
  margin-top: 0rem;
  padding: 2rem 0;
}

.not-selected {
  background-color: transparent !important;
  color: var(--text-color) !important;
}

.stock-filter-btn {
  background-color: var(--stock-filter-btn-color);
  color: var(--stock-filter-active-text);
  border: none;
  border-radius: 0.5rem;
  font-size: var(--font-med-l);
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.StockStatistics,
.StockNews {
  text-align: left;
  font-size: var(--font-med-l);
}

.StockNewsList {
  display: flex;
  flex-direction: row; /* Arrange items in a single row */
  gap: 2rem;
  overflow-x: auto; /* Enable horizontal scrolling */
  padding-bottom: 1rem; /* Optional: Adds spacing below the scroll area */
  white-space: nowrap; /* Prevent items from wrapping to the next line */
}

.options-table-heading th {
  color: var(--action-grey);
}

.StockNewsItem {
  flex: 0 0 auto;
  width: 25rem;
  overflow: auto;
  padding: 1rem;
  border-radius: 0.75rem;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  height: 20rem;
}
.StockTickerHeader {
  margin: 0;
  text-align: left;
  color: var(--action-grey);
}

.StockNewsImage {
  width: 100%;
  height: 12rem;
  object-fit: cover;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem;
}

.StockNewsTitle {
  color: var(--text-color);
  margin: 0.5rem 0;
  font-size: var(--font-med-s);
  white-space: wrap;
}

.StockNewsMeta {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: var(--text-color);
  margin-top: 0.5rem;
  font-size: var(--font-med-s);
}

.StockDescription,
.StockDescriptionHeader {
  text-align: left;
  font-size: var(--font-med-l);
}

.StockDescription {
  line-height: 2.2rem;
}
.StockStatistics,
.StockNews,
.StockDescription {
  margin-top: 3rem;
}

.StockNews {
  padding-bottom: 4rem;
}

.StatsHeader {
  font-size: var(--font-med-l);
  margin-bottom: 1rem;
}

.StockNewsHeader {
  font-size: var(--font-med-l);
  margin-bottom: 1.75rem;
}

.StatsTable {
  width: fit-content;
  border-collapse: separate; /* Allow column spacing */
  text-align: left; /* Align content to the left */
  border-spacing: 5rem 0; /* Column spacing */
  margin-left: -5rem;
  display: flex;
  flex-wrap: wrap;
}

.StatsTable td {
  padding: 0.5rem 0.5rem;
  padding-left: 0rem;
}

.StatsTable th {
  padding: 0.5rem 0.5rem;
}

.StatsTable td:nth-child(odd) {
  text-align: left;
  color: #666;
}

.StatsTable td:nth-child(even) {
  text-align: right;
  font-weight: bold;
}

.label-value-pair {
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-family: 'SF Pro Display Bold', sans-serif !important;
  width: 15rem;
}

.label-value-pair span {
  color: var(--action-grey);
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.stock-estimated-cost {
  font-size: var(--font-med-l);
}

.stock-dialog .stock-dt b {
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.stock-dialog-div .stock-estimated-cost b {
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.StockPosition {
  text-align: left;
  padding-bottom: 4rem;
  margin-top: 2rem;
}

.options-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  text-align: left;
  font-size: var(--font-med-l);
}

.options-table th,
.options-table td {
  padding: 1rem; /* Ensuring padding consistency */
  border-bottom: var(--border); /* Subtle borders similar to portfolio table */
  border-right: none !important;
  border-left: none !important;
}

.options-table th {
  font-family: 'SF Pro Display Bold', sans-serif !important;
  font-weight: 0 !important;
  text-align: left; /* Align table headers to the left */
}

/* Make table headers sticky */
.options-table th,
.StatsTable th {
  position: sticky;
  top: 0;
  z-index: 0;
  background-color: var(--main-color);
}

.options-table td {
  font-size: var(--font-med-l); /* Ensuring text size consistency */
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.options-table tbody tr:hover {
  background-color: var(--table-hover-bg); /* Hover effect */
}

.options-table td {
  background-color: var(--color-main);
  border: var(--border);
}

.options-table td:first-child {
  border-left: var(--border); /* Using the existing CSS variable for border */
}
/* Add border-right to the last column */
.options-table td:last-child {
  border-right: var(--border); /* Using the existing CSS variable for border */
}

.option-type-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.option-type-container label {
  font-size: var(--font-med-l);
}
.stock-purchase-modal {
  border: var(--border);
  padding: 2rem;
  border-radius: 2rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 29rem;
  width: calc(28% - 4rem);
}

.stock-p-modal-large {
  width: calc(30% - 4rem);
}

.StockInputLarge {
  width: 100% !important;
}

.stock-cash-available {
  color: var(--action-grey);
  font-size: var(--font-med-l);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.stock-input-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--border);
  padding: 1rem 0;
  width: 100%;
}

.StockInput {
  background-color: transparent;
  border: none;
  text-align: right;
  color: var(--text-color);
  flex-grow: 1;
  width: 100%;
}

.StockConfirmWrapper {
  display: flex;
  flex-direction: column;
  border-bottom: var(--border);
  gap: 1.5rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.StockConfirmWrapper span {
  font-size: var(--font-med-l);
}
.stock-toggle {
  font-size: var(--font-med-s);
}
.current-price-row {
  color: var(--stock-change-pos);
}

.current-price-row:hover {
  background-color: transparent !important;
}

.current-price-td {
  text-align: center;
  position: relative;
  padding: 0;
}

.current-price-text {
  font-size: var(--font-med-l);
}

.current-price-wrapper {
  display: inline-block;
  position: relative;
  padding: 1rem 2rem;
  background-color: var(--stock-change-pos);
  border-radius: 2rem;
  color: black;
  z-index: -1;
}

.current-price-wrapper::before,
.current-price-wrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  width: var(--line-width); /* Dynamic line width */
  height: 0.1rem;
  background-color: var(--stock-change-pos);
  transform: translateY(-50%);
  z-index: -1;
}

.current-price-wrapper::before {
  left: calc(-1 * var(--line-width)); /* Place the line to the left */
}

.current-price-wrapper::after {
  right: calc(-1 * var(--line-width)); /* Place the line to the right */
}

.stock-transaction-content {
  min-height: 80%;
  max-height: 80%;
}

.stock-congrats-btns {
  display: flex;
  gap: 1rem;
  align-self: flex-end;
  margin-top: 1rem;
}

.StatsGrid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(15rem, 1fr)
  ); /* Responsive grid */
  gap: 2rem; /* Uniform gap between items */
  width: 100%;
  justify-content: space-between; /* Distribute extra space between columns */
}
.label-value-pair {
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-family: 'SF Pro Display Bold', sans-serif !important;
  /* Removed fixed width */
}

.label-value-pair span {
  color: var(--action-grey);
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.ask-container {
  display: flex;
  flex: 1;
  align-items: center;
  width: fit-content;
  justify-content: space-evenly;
  border: 0.02rem solid currentColor; /* Dynamic color based on JSX */
  border-radius: 0.5rem;
}

.ask-price {
  padding: 1rem 2rem;
  border-right: 0.02rem solid currentColor;
  font-size: var(--font-med-l);
}

.option-button-container {
  display: flex;
  gap: 0.5rem;
}

.option-buy-button,
.option-sell-button {
  font-size: var(--font-med-l);
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.05rem;
  cursor: pointer;
  background-color: inherit; /* Transparent background */
}

.stock-transaction-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  position: relative;
}

.options-filters-and-table {
  position: relative;
}

.options-filters {
  position: sticky;
  top: 0; /* Sticks to the top of its container */
  z-index: var(--z-index-low); /* Ensures it appears above other elements */
  background-color: var(--main-color); /* Matches the background */
  padding: 1rem 0; /* Add padding to maintain spacing */
  display: flex;
}

.options-table-heading th {
  padding-top: 1.5rem;
  position: sticky;
  top: 4.5rem; /* Adjust based on the height of the options-filters */
  z-index: 5; /* Ensure the table heading is below the filters */
  background-color: var(--main-color);
}


.stock-market-error {
  color: var(--action-grey);
  font-size: var(--font-med-l);
  margin-top: 1rem;
}