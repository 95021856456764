.FooterContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 35px;
  background-color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.FooterItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: gray;
  font-size: 14px;
}

.FooterItem .FooterIcon {
  font-size: 20px;
  margin-bottom: 5px;
}

.FooterItem:hover {
  color: white;
}

.FooterText {
  margin-top: 2px;
}

.FooterContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  z-index: 1000;
}

.FooterItem {
  color: gray;
  text-align: center;
  flex: 1;
  font-size: 14px;
  border: none;
  background: none;
  cursor: pointer;
}

.FooterItem.active {
  color: white; /* Highlight active button */
}

.FooterIcon {
  font-size: 20px;
  margin-bottom: 5px;
}

/* Mobile-only footer display */
@media (max-width: 768px) {
  .FooterContainer {
    display: flex;
  }
}

@media (min-width: 769px) {
  .FooterContainer {
    display: none;
  }
}
