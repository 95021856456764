@import url('variables.css');

.LeadContainer {
  display: flex;
  flex-direction: column;
  max-width: var(--content-width);
  margin: 0 auto; /* Center-align the container */
  padding: 0 3rem;
}

.LeadSearchWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
}

.LeadCreateButton {
  font-size: var(--font-med-l);
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  height: 3rem;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.LeadMainContent {
  display: flex;
  margin-top: 2rem;
}

.LeadLeftContent {
  max-width: 73%;
  min-width: 73%;

  padding-right: 3rem;
  gap: 4rem;
  display: flex;
  flex-direction: column;
}

.LeadRightContent {
  width: 27%;
  padding-left: 3rem;
  border-left: var(--border);
  display: flex;
  flex-direction: column;
}

.LeadBestSectionHeader {
  display: flex;
  flex-direction: column;
}

.LeadBestTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LeadBestTitleSpan {
  font-size: var(--font-med-l);
  color: var(--action-grey);
  font-family: 'SF Pro Display Bold', sans-serif;
}

.LeadBestTitleH2 {
  margin: 0;
  font-size: var(--font-lg);
}

.LeadBestButtons {
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
  flex-wrap: wrap;
}

.LeadBestButtonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LeadCardContainer {
  display: grid;
  gap: 1rem;
  margin-left: -1rem; /* so that each card lines up better if needed */
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

/* LEFT SIDE CARDS */
.LeadCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
  border: none;
  border-radius: 1rem;
  padding: 1rem;
  gap: 1.25rem;
  position: relative;
  cursor: pointer;
}

.LeadCardRank {
  font-size: var(--font-sm);
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  color: var(--text-color);
  background-color: var(--main-color);
  border: var(--border);
  border-radius: 50%; /* Makes it a circle */
  width: 3rem; /* Adjust size */
  height: 3rem; /* Adjust size */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-med);
}

.LeadCardDetails {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  max-width: 14rem;
}

.LeadCardImageWrapper {
  /* Ensures the container is always a square */
  aspect-ratio: 1 / 1;
  width: 100%; /* Or set a fixed width, e.g., 200px, if desired */
  overflow: hidden;
  border-radius: 50%; /* Keep the circular look */
  position: relative; /* Needed if you want absolutely positioned children */
}

.LeadCardImage {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the container without distortion */
  display: block; /* Remove any inline spacing issues */
}

.LeadCardName {
  font-size: var(--font-med-l);
  margin: 0;
  margin-bottom: 0.5rem;
}

.LeadCardGain {
  font-size: var(--font-med-l);
  margin: 0;
  margin-bottom: 0.5rem;
}

.LeadGainArrow {
  font-size: var(--font-med-l);
}

.LeadCardLargestPosition {
  font-size: var(--font-med-l);
}

.LeadBrowseGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.LeadBrowseCard {
  background-color: var(--background-color);
  height: 5.5rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  font-size: var(--font-med-l);
  cursor: pointer;
}

.LeadBrowseCardImage {
  height: 100%;
  object-fit: cover;
}

.LeadFilter {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 3rem;
}

.LeadSectionWrapper {
  display: flex;
  gap: 1rem;
}

.LeadRightSideUserList {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.LeadRightSideUserCard {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s ease;
  position: relative;
}

.LeadRightSideUserCard:hover {
  background-color: var(--hover-color);
}

.LeadRightSideUserImageWrapper {
  width: 6rem;
  height: 6rem;
  overflow: hidden;
  border-radius: 5%;
  flex-shrink: 0;
}

.LeaderRadius {
  border-radius: 0.5rem;
}

.LeadRightSideUserImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.LeadRightSideUserDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.LeadRightSideUserName {
  font-size: var(--font-med-s);
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  flex-direction: column;
}

.LeadRightSideUserGain {
  font-size: var(--font-med-l);
}

.LeadRightSideLargestPosition {
  font-size: var(--font-med-l);
  color: var(--action-grey);
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
}

.leaderboard-table {
  width: 100%;
}

.leaderboard-table-wrapper {
  width: 100%; /* Ensure it stays inside the parent container */
  overflow-x: auto;
}

.separator {
  display: flex;
  font-size: 1.25rem;
  color: var(--action-grey);
  align-items: center;
  font-weight: bold;
  margin: 0.5rem 0; /* Add vertical spacing around the separator */
}

/* Backdrop styles for the blurred effect */
.LeadBackdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
  backdrop-filter: blur(1px); /* Apply blur */
  z-index: 10; /* Ensure it is below the modal */
  display: none; /* Initially hidden */
}

.LeadBackdrop.active {
  display: block; /* Show when active */
}

.ProfileImageSection {
  display: flex;
  flex-direction: row;
  justify-self: space-between;
  background-color: var(--background-color);
  padding: 1rem;
  border-radius: 0.5rem;
  gap: 2rem;
  max-width: 30rem;
}

.ProfileImageUploadBox {
  width: 10rem;
  height: 10rem;
  border: var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  position: relative;
  cursor: pointer;
  background-color: transparent;
}

.UploadIcon {
  font-size: var(--font-xl);
  color: var(--border-color);
  line-height: 1;
}

.UploadedImagePreview {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
  position: absolute;
}

.LeadFollowButton {
  position: absolute;
  bottom: 10%;
  left: 50%;
  width: 8rem;
  height: 3rem;
  transform: translateX(-50%);
  color: black;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  opacity: 0;
  font-size: var(--font-med-s);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.LeadCardImageWrapper:hover .LeadFollowButton {
  opacity: 1;
  transform: translateX(-50%) translateY(-5px);
}

.LeadFollowButton:hover {
  background-color: #ff9f43;
}
.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #111;
  border-radius: 1rem;
  padding: 2rem;
  width: 550px;
  box-shadow: 0 0 10px rgba(255, 191, 25, 0.8);
  z-index: 1000;
  color: #fff;
}

.tournament-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1rem 0;
  margin-bottom: 1.5rem;
}

.tournament-header {
  font-size: 2rem;
  font-weight: bold;
  color: #ffbf19;
}

.tournament-close-button {
  background-color: #333;
  color: #fff;
}
.tournament-close-button:hover {
  background-color: #444;
}

.tournament-category {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.tournament-label {
  font-size: 1rem;
  font-weight: bold;
  color: #aaa;
}

.tournament-input,
.tournament-textarea {
  background-color: transparent;
  color: #fff;
  width: 100%;
  font-family: 'Inter Bold';
  font-size: 11px;
  border: none;
}

.tournament-group-name {
  font-size: 15px;
  font-family: 'Inter Bold';
  margin-bottom: 1.5rem;
}

.tournament-textarea {
  height: 20px;
  resize: none;
}
.tournament-col {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tournament-row {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.tournament-category-text {
  font-size: 12px;
  font-family: 'Inter Bold';
  font-weight: bold;
  color: #fff;
}

.tournament-button-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100px;
}
.tournament-current-leader {
  display: flex;
  gap: '2px';
}

.tournament-create-button {
  background-color: #ffbf19;
  color: #000;
  font-weight: bold;
  padding: 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: center;
  flex: 1;
  border: none;
}

.tournament-create-button:hover {
  background-color: #e6ac00;
}

@media (max-width: 600px) {
  .popup-container {
    width: 90%;
    padding: 1.5rem;
  }

  .tournament-row {
    flex-direction: column;
  }

  .tournament-button-wrapper {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  /* General container */
  .LeadContainer {
    display: block;
    max-width: 100%; /* Ensure no horizontal overflow */
    padding: 2rem;
    margin: 0 auto;
    padding-bottom: 75px;
    overflow-x: hidden; /* Disable horizontal scrolling */
  }

  .LeadMobileContent {
    padding: 1rem;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }

  /* Adjust LeadBestSection */
  .LeadBestSection {
    width: 100%; /* Ensure it fits within the screen width */
  }

  /* Header and title */
  .LeadBestSectionHeader {
    align-items: flex-start;
    text-align: center;
    margin-bottom: 1rem;
  }

  .LeadBestTitle {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 1rem;
  }

  /* Center-align trades tab buttons */
  .LeadBestButtons {
    justify-content: flex-start;
    flex-wrap: nowrap;
    height: 35px;
    width: 100%;
    gap: 1rem;
  }
  .LeadBestButtons button {
    max-height: 35px;
    width: 90px;
    border-radius: 30px;
    font-size: 12px;
  }

  /* Wrapper styles */
  .LeadSortWrapper {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    align-items: center;
    width: 100%;
  }
  .LeadSortWrapper div {
    font-size: 12px;
    gap: 1rem;
  }

  .CreatePostFeedButton {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    background-color: #1a1a1a;
    color: #fff;
    border: 1px solid #444;
  }

  .CreatePostFeedButton.greenButton {
    background-color: #00ff00;
    color: #000;
  }

  .goldButton {
    background-color: #e6ac00;
    color: #000;
  }
  /* Adjust LeadCard for mobile */
  .LeadCard {
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    padding: 2rem;
    padding-right: 0px;
    gap: 1rem;
    max-width: 300px; /* Optional: Restrict card width for better control */
    box-sizing: border-box; /* Include padding and border in width calculation */
  }

  /* Rank styling */
  .LeadCardRank {
    font-size: 2rem;
    width: 3rem;
    height: 3rem;
    text-align: center;
    border: none;
    min-width: 2rem;
  }

  .LeadCardContainer::-webkit-scrollbar {
    display: none; /* Hide horizontal scrollbar */
  }

  /* Profile picture */
  .LeadCardImageWrapper {
    width: 15rem;
    height: 15rem;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: none; /* Simplify shadow for mobile */
  }

  .LeadCardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* User information */
  .LeadCardContent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
  }

  .LeadCardDetails {
    margin-top: 1rem;
    max-width: inherit; /* Inherit max width from parent */
    width: 100%; /* Dynamically match width of the parent */
    height: 100px;
  }

  .LeadCardName {
    font-size: var(--font-med-m);
    font-weight: bold;
    color: var(--text-color);
  }

  .LeadCardGain {
    font-size: var(--font-med-m);
  }
  .LeadGainArrow {
    font-size: var(--font-med-m);
  }

  /* Largest position and win rate */
  .LeadCardLargestPosition {
    font-size: 0.9rem;
    color: #aaa;
  }

  .LeadCardLargestPosition span {
    color: var(--text-color);
    font-size: var(--font-med-m);
  }

  .LeadCardMetrics {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .LeadCardMetric {
    font-size: 0.9rem;
    color: #aaa;
  }

  .LeadCardMetric span {
    font-weight: bold;
    color: #fff;
  }

  /* Footer */
  .LeadRightContent {
    display: none; /* Hide the right-side content */
  }

  .leaderboard-table-wrapper {
    display: none; /* Hide table for mobile */
  }
  .LeadBrowseGrid {
    grid-template-columns: 1fr 1fr; /* Two columns for mobile view */
  }
  .LeadBrowseCard {
    border-radius: 0.5rem !important;
  }
  .LeadCardContainer {
    display: flex; /* Use flexbox for horizontal alignment */
    flex-direction: row; /* Ensure cards align horizontally */
    overflow-x: auto; /* Enable horizontal scrolling */
    gap: 1rem; /* Add space between cards */
    padding: 1rem 0; /* Optional: padding for better visual spacing */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
  }
  .LeadCardJoinButton,
  .LeadCardFollowButton {
    display: none; /* Hide follow button */
  }

  .LeadCardContainer::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, and Edge */
  }
  .InvestorLeaderboardCardUser {
    border-bottom: var(--border);
  }
  .InvestorLeaderboardCardUser .LeadCardMobile {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: background-color 0.2s ease;
    position: relative;
    flex-direction: row; /* Keep horizontal layout */
    width: 100%; /* Full width */
    justify-content: space-between; /* Push elements apart */
  }
  .AllLeaderboardCardUser .LeadCard,
  .InvestorLeaderboardCardUser .LeadCard {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 2rem;
    border-radius: 0.5rem;
    flex-direction: row; /* Keep horizontal layout */
  }
  .AllLeaderboardCardUser .LeadCardUser,
  .InvestorLeaderboardCardUser .LeadCardUser {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    justify-content: center;
    flex-grow: 1; /* Allows it to take up available space */
    min-width: 40%; /* Adjust as needed */
    max-width: 40%;
  }
  .InvestorLeaderboardCardUser .LeadCardUserMobile {
    display: flex;
    font-size: var(--font-med-xs) !important;
    gap: 0.5rem;
    height: 100%;
  }
  .AllLeaderboardCardUser .LeadCardName,
  .InvestorLeaderboardCardUser .LeadCardName {
    display: flex;
    gap: 0.5rem;
    height: 100%;
  }
  .CollegeLeaderboardCardUser .LeadCardImageWrapper,
  .AllLeaderboardCardUser .LeadCardImageWrapper {
    flex-shrink: 0;
    height: 15rem;
    overflow: hidden;
    width: 15rem;
    border-radius: 5px;
  }
  .InvestorLeaderboardCardUser .LeadCardImageWrapper {
    flex-shrink: 0;
    height: 9rem;
    overflow: hidden;
    width: 9rem;
  }
  .AllLeaderboardCardUser .LeadCardRank,
  .InvestorLeaderboardCardUser .LeadCardRank {
    font-size: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    border: none;
    min-width: 2rem;
    position: relative;
    justify-content: flex-start;
  }
  .AllLeaderboardCardUser .LeadCardJoinButton,
  .InvestorLeaderboardCardUser .LeadCardFollowButton {
    display: flex;
    justify-content: flex-end;
    margin-left: -1.5rem;
  }
}
