@import url('variables.css');

.cp-CreatePostContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: var(--content-width);
  margin: 0 auto; /* Center-align the container */
  padding: 2rem 3rem;
}

.cp-CreatePostList {
  list-style-type: none;
  padding: 0;
  font-family: 'SF Pro Display Bold';
  font-size: 1.417rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.cp-QuotePostCaption {
  color: var(--action-grey);
}
.cp-CreatePostItem,
.cp-CreatePostDrafts {
  cursor: pointer;
  position: relative;
  text-align: center;
  width: 14.5rem;
  height: 3.583rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-radius: 5px;
}

.cp-CreatePostItem.cp-CreatePostActive,
.cp-CreatePostDrafts.cp-CreatePostActive {
  background-color: #e6ffec;
  font-family: 'SF Pro Display Bold';
  color: rgb(50, 205, 50);
}

.cp-CreatePostDrafts {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cp-CreatePostListSpan {
  margin-right: auto;
}

.cp-CreatePostListSpanSub {
  margin-left: 1rem;
}

.cp-CreatePostHr {
  margin: 1rem 0;
  width: 15rem;
}

.cp-CreatePostSection {
  margin-bottom: 1.5rem;
  width: 100%;
}

.cp-CreatePostSectionHeader {
  font-family: 'SF Pro Display Bold';
  font-size: var(--font-med-xs);
  margin-bottom: 0.5rem;
  text-align: left;
  width: 100%;
  padding-left: 1rem;
  color: #b3b3b3;
}

.cp-CreatePostCheckmark {
  color: green;
}

.cp-CreatePostSidebar {
  width: 16.67rem;
  padding: 1rem;
  border-right: 1px solid #ccc;
}

.cp-CreatePostMiddle {
  width: calc(66% - 2rem);
  padding-right: 2rem;
}

.cp-CreatePostFiltersRow {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.cp-CreatePostMiddle.expanded {
  width: 100%; /* Adjust this value as needed */
  padding: 0;
  overflow: hidden;
}

.cp-CreatePostRightSidebar {
  width: calc(34% - 4rem);
  border: var(--border);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
  gap: 1.5rem;
  padding: 2rem;
}

.cp-CreatePostTitle,
.cp-CreatePostCaption,
.cp-CreatePostBody,
.cp-CreatePostImageUpload {
  border-radius: 0.416rem;
  padding: 1rem;
  box-sizing: border-box;
  border: none;
  padding: 0;
}

.cp-CaptionInputContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
}
.cp-CaptionLabel {
  font-size: var(--font-med-xl);
  color: var(--text-color);
}

.cp-CaptionInput {
  width: 100%;
  font-size: var(--font-med-xl);
  color: var(--text-color);
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
}

.cp-PostImage-m {
  object-fit: contain;
  border-radius: 0.416rem;
  margin: 0.5rem;
  max-width: 100%;
  max-height: 100%;
}

.cp-PostImageDraft {
  object-fit: contain;
  border-radius: 0.416rem;
  margin: 0.5rem;
  max-width: 50%;
  max-height: 100%;
}
.cp-CreatePostTitle {
  width: 100%;
  height: 5.5rem;
  font-size: 3rem;
  font-family: 'SF Pro Display Bold';
  overflow: auto;
  resize: none;
  text-align: left;
  background-color: var(--background-color-input);
  color: var(--text-color);
}

.cp-CreatePostTitle::placeholder {
  color: var(--placeholder-color);
}

.cp-CreatePostMain {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-template-rows: auto auto;
  gap: 1.33rem;
}
.cp-FullWidth {
  grid-column: 1 / span 2;
}

.cp-CreatePostRow {
  display: contents;
}

.cp-CreatePostImageUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border-radius: 0.4166rem;
  border: none;
  text-align: center;
  min-height: 3rem;
  width: 10rem;
  background-color: var(--background-color);
  font-size: var(--font-med-s);
  font-family: 'SF Pro Display Bold';
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: var(--text-color);
}

.cp-CreatePostImageUpload input {
  display: none;
}

.cp-ImageWrapper {
  position: relative;
}

.cp-RemoveImageIcon {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  background: var(--background-color);
  cursor: pointer;
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
  border: 0.5px solid var(--text-color);
  font-size: 1.35rem;
  text-align: center;
}

.cp-SelectedImage {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 0.67rem;
  position: relative;
}

.cp-AddImageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3rem;
}

.cp-AddImageText {
  font-size: 1em;
  color: var(--text-color);
}

.cp-CreatePostCaption {
  grid-column: 2;
  height: 25rem;
  resize: none;
}

.cp-CreatePostBody {
  grid-column: 1 / -1;
  resize: none;
  font-size: var(--font-med-xl);
  line-height: 1.5;
}

.cp-PreviewTitle {
  font-size: var(--font-xl);
  font-family: 'SF Pro Display Bold';
  overflow: auto;
  text-align: left;
  padding-bottom: 1rem;
  border-bottom: var(--border);
}

.cp-PreviewImage {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  object-fit: contain;
}

.cp-PreviewImageContainer {
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.05rem dashed var(--text-color);
  border-radius: 0.416rem;
  background-color: var(--background-color);
}

.cp-PreviewBody {
  font-size: var(--font-med-xl);
  line-height: 1.5;
  margin-bottom: 1rem;
  overflow: auto;
  max-height: 60rem;
  text-align: left;
  white-space: pre-wrap;
}

.cp-PreviewTopicLine {
  text-align: left;
  font-size: var(--font-med-xl);
}

.cp-PostPreviewSpan {
  font-size: var(--font-med-xl);
  text-align: left;
  margin: 0;
}

.cp-CreatePostCaptionSpan {
  font-size: var(--font-med-xs);
  margin-top: 1rem;
  text-align: left;
  align-self: flex-start;
  color: var(--action-grey);
}

.cp-CreatePostAuthor {
  font-size: var(--font-sm);
  color: #b3b3b3;
  margin-bottom: 1rem;
  text-align: left;
}

.cp-PreviewFooter {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.cp-PreviewButton {
  border-radius: 2.5rem;
  text-align: center;
  cursor: pointer;
  border: var(--border);
  height: 3.5rem;
  font-size: var(--font-med-s);
}

.cp-PreviewButton.cp-Post {
  background-color: var(--stock-change-pos);
  color: black;
  border: none;
}

.cp-PreviewButton.cp-Save {
  background-color: var(--background-color);
  color: var(--text-color);
}

.cp-CreatePostTopicLine {
  margin-bottom: 1rem;
  color: #b3b3b3;
  font-size: var(--font-med-xl);
}

.cp-CreatePostTopicLineInput {
  width: 100%;
  font-size: var(--font-med-xl);
  color: var(--text-color);
  border: none;
  outline: none;
  padding: 0;
  margin-bottom: 1rem;
  background-color: transparent;
}

.cp-CreatePostTopicLineInput::placeholder {
  color: var(--placeholder-color);
}

.cp-DraftList {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: flex-start;
  width: 100%;
}

.cp-DraftItem {
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  text-align: left;
  overflow: auto;
  margin-bottom: 1rem;
}

.cp-DraftItemWrapper {
  border-bottom: var(--border);
  width: calc(100% - 3rem);
}

.cp-DraftItemTopicLine {
  font-size: var(--font-sm);
  font-family: 'SF Pro Display Bold';
  color: #b3b3b3;
  margin-top: 0.833rem;
}
.cp-DraftItemTitle {
  font-size: var(--font-med-l);
  font-family: 'SF Pro Display Bold';
  margin-top: 0.833rem;
}

.cp-DraftItemContent {
  font-size: var(--font-med-l);
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.833rem;
}

.cp-SidebarHr {
  border: 0.05rem solid #ccc;
}

.cp-DraftActions {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cp-EditButtonDraft,
.cp-DeleteButtonDraft {
  width: 8.64rem;
  height: 3.5rem;
  margin-right: 0.5rem;
  border: none;
  border-radius: 2.5rem;
  cursor: pointer;
  font-family: 'SF Pro Display Bold';
  font-size: var(--font-med-s);
}

.cp-EditButtonDraft {
  background-color: var(--stock-change-pos);
  color: black;
}

.cp-DeleteButtonDraft {
  background-color: var(--background-color);
  color: var(--text-color);
}

.cp-repost-modal {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 12.5rem;
}

.cp-repost-modal-item {
  padding: 0.5rem;
  cursor: pointer;
  text-align: left;
  border: none;
  background: none;
  width: 100%;
}

.cp-repost-modal-item:hover {
  background-color: #f5f5f5;
}

.cp-QuotePostTitle {
  text-align: left;
  margin: 1.1rem 0 0 0;
  padding: 0;
  line-height: 2rem;
  font-size: var(--font-lg);
}

.cp-CreatePostBodyQuotePost {
  grid-column: 1 / -1;
  resize: none;
  font-size: var(--font-med-xl);
  padding: 1rem;
  box-sizing: border-box;
  border: none;
  padding: 0;
  line-height: 1.5;
}

.cp-QuotePostContainer {
  display: flex;
  flex-direction: column;
  border: var(--border);
  border-radius: 10px;
  margin-top: 1rem;
  background-color: transparent;
  width: calc(100% - 2rem);
  padding: 1rem;
  text-align: left;
  margin-bottom: 1rem;
  overflow: auto;
}
.cp-AssetSuggestion:hover {
  background-color: var(--hover-color);
}
.cp-QuotePostHeader {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.cp-QuoteProfilePic {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.cp-QuotePostInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.cp-QuotePostInfoWrapper {
  width: 100%;
}

.cp-QuotePostAuthor {
  font-family: 'SF Pro Display Bold';
  font-size: var(--font-med-l);
}

.cp-QuotePostUsername {
  color: #657786;
  margin-left: 0.5rem;
}

.cp-QuotePostDetails {
  color: var(--action-grey);
  font-size: var(--font-med-l);
}

.cp-QuotePostContent {
  font-size: var(--font-med-l);
  line-height: 2.2rem;
  overflow-wrap: break-word;
  overflow: auto;
  white-space: pre-wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.cp-QuotePostContent a {
  color: var(--stock-change-pos);
  text-decoration: underline;
}
.cp-DraftItemBody a {
  color: var(--stock-change-pos);
  text-decoration: underline;
}

.cp-AddElementButton {
  background-color: var(--background-color);
  border: none;
  border-radius: 2rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3.5rem;
  max-height: 3.5rem;
  overflow: auto;
  cursor: pointer;
  font-size: var(--font-med-s);
  color: var(--text-color);
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.cp-BackDraft {
  padding: 2rem 0rem;
}

.cp-NoDrafts {
  margin: 0 auto;
}

.cp-CreatePostBody {
  width: 100%;
  resize: vertical;
  overflow: auto;
  text-align: left;
  line-height: 1.5;
}

.cp-CreatePostBody[contenteditable]:empty:before,
.cp-CreatePostBodyQuotePost[contenteditable]:empty:before {
  content: attr(placeholder);
  color: var(--text-color);
}

.cp-CreatePostBody a {
  color: var(--stock-change-pos);
  text-decoration: underline;
}

.cp-CreatePostBodyQuotePost a {
  color: var(--stock-change-pos);
  text-decoration: underline;
}

.cp-PreviewBody a {
  color: var(--stock-change-pos);
  text-decoration: underline;
}

.cp-CreatePostBody:focus {
  outline: none;
}

.cp-CreatePostBodyQuotePost:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .PostTradeContainer {
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    background-color: var(--main-color);
    gap: 1rem; /*To Add spacing between sections */
    margin-top: 2px !important;
    padding: 1rem; /* Padding inside the container */
    min-height: 200px; /* Ensures consistent minimum height */
    position: relative; /* Required for child element positioning */
  }

  .PostTradeHeader {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: left;
  }

  /* Content container with grid layout for labels and values */
  .PostTradeContentContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 equal-width columns */
    gap: 1rem 1rem; /* Row and column spacing */
    align-items: center; /* Align content vertically */
    justify-content: start; /* Align content horizontally */
  }

  .PostTradeLabelAndValue {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem; /* Spacing between label and value */
  }

  .PostTradeLabelAndValue span {
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Prevent overflow */
    text-overflow: ellipsis; /* Add ellipsis for overflowing text */
    font-size: 15px;
  }

  .PostTradeButtonsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates 3 equal columns */
    gap: 1rem; /* Adjust spacing between buttons */
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center the grid */
    padding: 2rem 0; /* Padding above and below */
    margin: 0 auto; /* Center the container */
  }

  .cp-CreatePostContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Prevent layout overlap */
    gap: 1rem;
  }

  .cp-CreatePostList {
    width: 100%; /* Ensure list items take full width */
    max-width: var(--content-width); /* Constrain width */
  }

  .cp-CreatePostContainer::after {
    content: '';
    display: table;
    clear: both;
  }

  .PostTradeButtonsContainer button {
    font-size: 10px;
    min-height: 50px !important;
    width: 90px;
    border-radius: 3rem;
    padding: 0px 2px 0 2px !important;
  }

  /* Individual button styling */
  .CreatePostFeedButton {
    display: inline-flex;
    justify-content: center; /* Center-align text horizontally */
    align-items: center; /* Center-align text vertically */
    height: 40px; /* Explicit height to prevent clipping */
    padding: 0.5rem 1rem; /* Adjust padding for spacing */
    font-size: 14px; /* Ensure consistent font size */
    border-radius: 2rem; /* Round button corners */
    border: var(--border) !important;
    color: var(--text-color); /* Text color */
    transition: background-color 0.3s ease; /* Hover transition */
  }

  .PostTradeButtonsContainer button {
    pointer-events: none; /* Disable click events */
    opacity: 0.5; /* Make buttons appear disabled */
    cursor: not-allowed; /* Change cursor to indicate the button is disabled */
  }

  /* Hover state for buttons */
  .CreatePostFeedButton:hover {
    background-color: var(--hover-color); /* Change background on hover */
    color: var(--text-hover-color); /* Change text color on hover */
  }

  /* Responsive adjustments for larger screens */
  @media screen and (min-width: 769px) {
    .PostTradeButtonsContainer {
      justify-content: flex-start; /* Align buttons to the start */
      gap: 1rem; /* Increase spacing between buttons */
    }
    .PostTradeButtonsContainer button {
      min-height: 40px;
    }

    .CreatePostFeedButton {
      max-width: none; /* Remove width restriction */
      padding: 0.8rem 1.5rem; /* Increase padding */
      font-size: var(--font-med-m); /* Larger font size */
    }
  }
}
