.MarketsV2 {
  display: flex;
  flex-direction: column;
  max-width: var(--content-width);
  margin: 0 auto;
  padding: 0 3rem;
  gap: 3rem;
  padding-bottom: 3rem;
}

.mv2-watchlists {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.mv2-watchlists-header {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.mv2-watchlist-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.mv2-watchlists-h2 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: var(--font-lg);
  text-align: left;
}

/* .mv2-watchlist-btn-wrapper {
  position: relative;
} */

.mv2-watchlist-btn-wrapper {
  position: relative;
  display: inline-block;
}

.keyword-x {
  position: absolute;
  border: none;
  cursor: pointer;
  visibility: hidden; /* Hidden by default */
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: var(--font-med-xs);
  height: 1.5rem;
  right: -0.5rem;
  top: -0.5rem;
  width: 1.5rem;
  border: 0.05rem solid var(--text-color);
  border-radius: 50%;
}

/* Show the keyword-x button only on hover */
.mv2-watchlist-btn-wrapper:hover .keyword-x {
  visibility: visible; /* Make it visible */
  opacity: 1;
}

.mv2-watchlist-delete-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.mv2-create-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.mv2-create-input {
  width: 100%;
  border: var(--border);
  background-color: var(--input-color);
  color: var(--text-color);
  font-size: var(--font-med-s);
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.mv2-stock-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  height: 6rem;
  overflow: auto;
}
.mv2-stock-item-col {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
  max-width: 36%;
  align-self: center;
}
.mv2-stock-item-span {
  font-size: var(--font-med-l);
  align-items: flex-end;
}
.mv2-watchlists-wrapper {
  display: flex;
  flex-direction: column;
}

.mv2-stock-item {
  border-bottom: var(--border);
  padding: 1rem 0;
  font-size: var(--font-med-l);
  overflow: auto;
  align-items: flex-start;
  cursor: pointer;
  gap: 1rem;
  width: 100%;
}
.mv2-stock-item:last-child {
  border-bottom: none;
}
.mv2-activelist-flex {
  display: grid; /* Use CSS Grid */
  grid-template-columns: repeat(
    3,
    calc((100% - 2 * 3rem) / 3)
  ); /* Account for 2 gaps */
  gap: 3rem; /* Add spacing between items */
  max-width: 100%; /* Ensure it fits within the parent */
  row-gap: 0rem;
  margin-top: 1.5rem;
}

.mv2-stockitem-graph {
  width: 25%;
  align-self: center;
  margin-left: auto;
  margin-right: 3rem;
}

.mv2-tabs {
  display: flex;
  margin: 1.5rem 0;
  gap: 1rem;
  row-gap: 1.5rem;
  width: 100%;
  flex-wrap: wrap;
}

.mv2-pagination-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.mv2-pagination-buttons {
  margin-top: 1.5rem;
  font-size: var(--font-med-l);
}

.mv2-row2-wrapper {
  display: flex;
  justify-content: space-between;
}

.mv2-keyindicators {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.mv2-keyindicators-grid {
  display: grid; /* Switch to grid layout */
  grid-template-columns: repeat(3, 1fr); /* 3 equal-width columns */
  gap: 0.8rem; /* Consistent spacing between rows and columns */
  margin-bottom: 1.5rem;
}

.mv2-indicator-wrapper {
  border-radius: 5px;
}

.mv2-indicator-card {
  background-color: var(--background-color);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  overflow: auto;
  padding: 1rem;
  gap: 1rem;
}
.mv2-indicator-header {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: var(--font-med-l);
}

.mv2-indicator-dot {
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  margin-left: 0.5rem;
}

.mv2-indicator-name {
  font-size: var(--font-med-m);
}

.mv2-indicator-value {
  font-size: var(--font-med-l);
}

.mv2-indicator-change {
  font-size: var(--font-med-m);
}

.mv2-section-filters {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.mv2-pagination-arrows {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: end;
}

.mv2-section-filters-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.mv2-us-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: left;
}

.mv2-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1.5rem;
}

.mv2-thead th {
  color: var(--action-grey);
}

.mv2-table th,
.mv2-table td {
  text-align: left;
  padding: 2rem;
  border-bottom: var(--border);
  font-size: var(--font-med-l);
}

.mv2-thead-tr th {
  padding-left: 0rem;
}

.mv2-table tr:hover {
  background-color: var(--hover-color);
}

.mv2-table-rank {
  color: var(--action-grey);
}

.mv2-tbody td {
  padding-left: 0rem;
}

.mv2-thead-tr th {
  padding-top: 0rem;
}

.mv2-stock-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 13.5rem;
  height: 18rem;
  cursor: pointer;
  overflow: auto;
  background-color: var(--background-color);
  text-align: left;
  font-size: var(--font-med-l);
  border: none;
}

.mv2-stock-card:hover {
  background-color: transparent !important;
}

.mv2-stock-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.mv2-stock-card-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.mv2-stock-card-footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.mv2-stock-card-graph {
  align-self: center;
  width: 100%;
}

.mv2-thead-tr th {
  font-family: 'SF Pro Display Bold', sans-serif;
}

.sorting-options {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0 0 0;
}

.date-added-button {
  color: var(--text-color);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: var(--font-med-l);
  background-color: inherit;
  padding: 1rem 0;
  max-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.date-added-button span {
  font-size: 14px;
}

/* Ensure sections stack vertically on mobile */
@media screen and (max-width: 768px) {
  /* General container for mobile */
  .MarketsV2 {
    display: flex;
    flex-direction: column;
    max-width: var(--content-width);
    margin: 0 auto;
    padding: 0 2rem; /* Reduced padding for mobile */
    gap: 2rem;
    padding-bottom: 75px;
  }

  /* Ensure Key Indicators appears first */
  .mv2-keyindicators {
    order: -1; /* Push to top */
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    margin-top: 1.5rem;
  }
  .mv2-activelist-flex {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .mv2-keyindicators-grid {
    grid-template-columns: repeat(3, 1fr); /* 2 columns instead of 3 */
    gap: 0.5rem;
  }
}
