@import url('variables.css');

/* Container fills entire viewport */
.mo-v-mobile-screen {
  width: 100vw;
  height: 100vh;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
}

/* Marquee section at the top */
.mo-v-over-hid {
  overflow: hidden;
  width: 100vw;
}

/* The main content area (below the marquee) */
.mo-v-content {
  flex: 1; /* Take up remaining space */
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  text-align: center;
  padding: 2rem;
}

/* Headings & Text */
.mo-v-mobile-heading {
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 2rem;
}

.mo-v-mobile-paragraph {
  font-size:16px;
  margin-bottom: 2rem;
}

/* Buttons - using pill shape and spacing */
.mo-v-active {
  background-color: var(--stock-change-pos); /* Bright green */
  color: #000000;
  border: none;
  padding: 12px 30px;
  font-size: 18px;
  border-radius: 9999px; /* Pill shape */
  cursor: pointer;
  margin-bottom:15px;
  margin-top: 10px;
  font-weight: bold;
}

.mo-v-button {
  background-color: var(--background-color); 
  color: var(--text-color );
  border: none;
  padding: 12px 30px;
  font-size: 18px;
  border-radius: 9999px;
  cursor: pointer;
  margin-bottom: 15px;
  font-weight: bold;

}

/* Modal styles */
.mo-v-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
}

.mo-v-fade.mo-v-show {
  display: flex;
  background-color: rgba(0,0,0,0.5);
}


.mo-v-modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mo-v-horizontal-padding {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}


.mo-v-padding-3 {
  padding: 1rem;
}

/* Input fields inside modal */
.mo-v-npt-mobile {
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 14px;
  border-radius: 0.25rem;
  border: 0.05rem solid var(--text-color);
  font-size:17px;
  box-sizing: border-box;
  background-color: var(--background-color-input);
  color: var(--text-color);
}

/* Button group inside modal */
.mo-v-button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.mo-v-cancel-button {
  background-color: #cccccc;
  color: #000000;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: var(--font-med-xl);
  border-radius: 0.25rem;
  margin: 0.5rem;
  cursor: pointer;
}

/* Marquee list styles */
.mo-v-ulls {
  display: flex;
  list-style: none;
  padding: 1.5rem;
  border-bottom: var(--border);
  margin: 0;
  font-size: var(--font-med-xs);
}

.mo-v-ulls li {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  font-size: var(--font-med-xs);
}

/* Colors for percentages */
.mo-v-color-green {
  color: var(--stock-change-pos); 
}

.mo-v-color-red {
  color: var(--stock-change-neg); 
}

.mo-v-modal-dialog {
  position: relative;
  width: 450px;
  max-width: 100%; 
  margin: auto;
}

.mo-v-modal-content {
  background-color: var(--main-color);
  border-radius: 0.5rem;
  overflow: hidden;
  border: 0.05rem solid var(--text-color);
  padding: 1rem; 
  width: 100%;
}

.mo-v-modal-body {
  position: relative;
  padding: 2rem;
}