@import url('variables.css');

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  overflow: visible;
}

.KeyIndicators {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: scroll;
  gap: 2rem;
}

.KeyIndicators .indicator {
  font-size: var(--font-med-xs);
  color: var(--text-color);
  white-space: nowrap;
}

.positive {
  color: #1dcf5b;
}

.negative {
  color: #ca222c;
}

.Icons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border: var(--border);
  border-radius: 2rem;
  padding-right: 1rem;
  cursor: pointer;
}

.icon-button {
  background: var(--background-color);
  border: none;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  padding: 0;
  margin: 0;
  font-size: var(--font-med-xl);
  color: var(--text-color);
}

.icon-button .fa-icon {
  height: 1.5rem;
  width: auto;
}

.icon-button:hover {
  margin: 0;
}

.profile-pic {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  box-sizing: border-box;
}

.chevron-icons {
  display: flex;
  font-size: var(--font-sm);
}

.vestr-button {
  background: var(--background-color);
  border: none;
  border-radius: 2rem;
  height: 3rem;
  width: 6rem;
  font-size: var(--font-med-s);
  cursor: pointer;
  color: var(--text-color);
}

.profile {
  display: flex;
  align-items: center;
  gap: 0.833rem;
  background: var(--background-color);
  height: 3rem;
  border-radius: 2rem;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  padding: 0 1rem;
  max-width: 15rem;
  min-width: 3rem;
}

.profile-nav-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
}

.profile-nav-container img {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

.profile-nav-container span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  text-align: right;
  font-size: var(--font-sm);
}

.profile-nav-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.dropdown-button {
  background: none;
  border: none;
  font-size: var(--font-med-xs);
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--text-color);
  padding: 0;
  white-space: nowrap;
}

.HeaderContainer {
  width: 100%;
}

.SubHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 3rem;
  background-color: var(--main-color);
  position: relative;
  flex-wrap: wrap;
  row-gap: 2rem;
  overflow: visible;
  max-width: var(--content-width);
  margin: 0 auto;
}

.SubHeaderWrapper {
  border: var(--border);
  border-left: none;
  border-right: none;
}
.nav-items {
  display: flex;
  gap: 5rem;
  align-items: center;
}

.nav-button {
  background: none;
  border: none;
  font-size: var(--font-med-l);
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--text-color);
  position: relative;
  text-decoration: none;
}

.search-input {
  height: 2.5rem;
  width: 20rem;
  background-color: var(--background-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: var(--font-med-xs);
  margin-right: 1rem;
}

.subheader-ads {
  display: flex;
  align-items: center;
}

.ad-space {
  height: 2.5rem;
  background: var(--background-color);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem 0 0 0.5rem;
  font-size: var(--font-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  color: var(--text-color);
  flex: 0 0 auto;
}

.vestr-logo {
  height: 3.5rem;
}

.vestr-logo-2 {
  height: 3.5rem;
}

.nav-button.active {
  color: var(--nav-color-active);
  font-family: 'SF Pro Display Bold';
}

.nav-button.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.25rem;
  background-color: var(--nav-color-active);
  position: absolute;
  bottom: -2rem;
  left: 0;
}

.disabled {
  pointer-events: none;
  color: grey;
}

.hr-header {
  margin: 0;
  border: 0;
  border-top: var(--border);
  width: 100%;
}

.h-search-container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: visible;
  background-color: var(--main-color);
  border: var(--border);
  border-bottom: none;
  border-top: none;
  margin-right: 1rem;
}

.profile-pic-header {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  cursor: pointer;
}

.h-search-input::placeholder {
  color: var(--placeholder-color);
  text-align: left;
}

.h-search-result-item {
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem;
  cursor: pointer;
  border-bottom: var(--border);
  font-size: var(--font-med-xs);
  height: 2.5rem;
  width: calc(100% - 2rem) !important;
}

.h-search-result-item:last-child {
  border-bottom: none;
}

.h-search-result-item:hover {
  background-color: var(--hover-color);
}

.h-search-result-pic {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

/* Existing styles... */

.Header .KeyIndicators {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.Header .marquee-content {
  display: inline-block;
  animation: marquee var(--scroll-duration) linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.Header .indicator {
  display: inline-block;
  margin-right: 2rem;
}

/* Profile modal styling */
.profile-modal {
  z-index: var(--z-index-high);
  position: absolute;
  top: 5.5rem;
  right: 4rem;
  background-color: var(--main-color);
  border-radius: 1.2rem 0 1.2rem 1.2rem;
  width: 20rem;
  color: var(--text-color);
  border: var(--border);
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.profile-modal::before {
  content: '';
  position: absolute;
  right: 0rem; /* 20px */
  top: -1.5rem; /* 10px */
  border-width: 0.75rem; /* 5px */
  border-style: solid;
  border-color: transparent transparent var(--border-color) transparent;
}

.profile-modal-item {
  cursor: pointer;
  text-align: left;
  padding: 1rem;
  font-size: var(--font-med-xl);
}

.profile-modal-item:not(:last-child) {
  border-bottom: var(--border);
}

.profile-modal-item:last-child {
  border-radius: 0 0 0.5rem 0.5rem;
}
.profile-modal-item:first-child {
  border-radius: 0.5rem 0.5rem 0 0;
}

.profile-modal-item:hover {
  background-color: var(--hover-color);
}

.h-search-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 40rem;
  overflow: visible;
  background-color: var(--main-color);
  border-bottom: none; /* Remove bottom border for continuity */
  padding-right: 1rem;
  padding-left: 1rem;
}
.h-search-input {
  height: 3.5rem;
  background-color: var(--background-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: var(--font-med-s);
  color: var(--text-color);
  box-sizing: border-box;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: 'SF Pro Display Bold', sans-serif;
}

.search-toggle-buttons {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.h-search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: calc(100% - 2rem);
  max-height: 75rem;
  overflow: auto;
  padding: 1rem;
  margin-left: -0.05rem;
  background: var(--main-color);
  border: var(--border); /* Add border here for the dropdown */
  border-top: none; /* Remove top border to make it continuous with the container */
  border-radius: 0 0 0.5rem 0.5rem; /* Rounded corners only at the bottom */
  z-index: var(--z-index-med);
}

.h-stock-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  padding: 1rem 1rem;
  cursor: pointer;
  font-size: var(--font-med-s);
  border-bottom: var(--border);
  line-height: 1.5;
}

.h-user-info-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: var(--font-med-s);
  gap: 0.25rem;
}
.h-user-span {
  color: var(--action-grey);
}

.h-stock-item:last-child {
  border-bottom: none;
}

.h-stock-item:hover {
  background-color: var(--hover-color);
}

.h-stock-col {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.t-left {
  text-align: left;
}

.t-right {
  text-align: right;
}

.h-stock-ticker {
  color: var(--action-grey);
}

.vest-logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease; /* Smooth transition */
}

.vest-logo-container:hover {
  transform: scale(1.1); /* Scale up on hover */
}

.vest-logo-main {
  font-size: 2.5rem; /* Adjust as necessary */
  transition: font-size 0.3s ease; /* Smooth font-size transition */
}

.vestr-logo {
  width: 2.5rem;
  transition: transform 0.3s ease; /* Smooth image size transition */
}

.vest-logo-main {
  cursor: pointer;
  font-style: italic;
  font-size: var(--font-xl);
  color: var(--text-color);
  font-weight: 999;
  margin-right: 1rem;
}

.h-search-result-item.active {
  background-color: var(--hover-color); /* Highlight color */
  color: var(--text-highlight-color); /* Optional for text color */
}

@media screen and (max-width: 768px) {
  .SubHeaderWrapper {
    display: none;
  }

  .KeyIndicators .indicator {
    font-size: var(--font-med-xl);
    color: var(--text-color);
    white-space: nowrap;
  }
}
