.al-container {
  display: flex;
  flex-direction: column;
  max-width: var(--content-width);
  margin: 0 auto;
  padding: 0 1rem;
  overflow: visible;
}

.al-header {
  display: flex;
  flex-direction: column;
}

.al-header-h2 {
  font-size: var(--font-lg);
  margin: 0;
}

.al-header-p {
  font-size: var(--font-med-l);
  color: var(--action-grey);
  margin: 1.5rem 0;
}

.al-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-top: 2rem;
  margin-left: -1rem;
}
.LeadCardImageWrapper:hover .JoinButton {
  display: block;
}

.JoinButton {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary-color);
  color: black;
  padding: 5px 10px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.JoinButton:hover {
  background-color: var(--primary-hover-color);
}

/* MOBILE VIEW: Change to vertical scrolling */
@media screen and (max-width: 768px) {
  .al-main-container {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Space between items */
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 80vh; /* Ensure it fits within the viewport */
    padding-right: 1rem; /* Prevent cutting off on the right */
  }
  .al-container {
    padding: 0 2rem;
    padding-right: 0px;
  }
  .al-header-p {
    display: none;
  }
  .al-main-container {
    max-width: 100%; /* Prevent elements from exceeding the screen width */
    overflow-x: hidden; /* Prevent overflow content */
    box-sizing: border-box; /* Include padding and borders in the element's width */
  }
}
