@import url('variables.css');

.PostContainer {
  display: flex;
  border-bottom: var(--border);
  padding: 2rem 0rem;
}

.ProfilePicContainer {
  cursor: pointer;
}

.ProfilePic {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.PostFollowUserBtn {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  color: var(--invert-text-color);
  border: 0.05rem solid var(--main-green);
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--stock-change-pos);
  cursor: pointer;
}

.PostContentContainerWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 1.25rem;
  overflow-wrap: break-word;
  max-width: calc(100% - 5.5rem);
}

.PostContainerWrapper {
  display: flex;
  flex-direction: column;
}

.ReposterText {
  text-align: left;
  padding: 1.5rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
  color: #707070;
  font-size: var(--font-med-l);
}

.LeftActions {
  color: #707070;
}

.PostHeader {
  display: flex;
  justify-content: left;
  flex-direction: row;
  font-size: var(--font-med-l);
}

.PostInfo {
  display: flex;
  flex-direction: column;
}

.PostAuthor {
  cursor: pointer;
}

.Verified {
  color: var(--stock-change-pos);
}

.PostDetails {
  color: var(--action-grey);
  cursor: pointer;
}

.PostContent {
  text-align: left;
  cursor: pointer;
}

.PostContentP {
  font-size: var(--font-med-l);
  line-height: 2.2rem;
  overflow-wrap: break-word;
  overflow: auto;
  margin: 1rem 0;
}

.PostContentP a {
  color: var(--stock-change-pos);
}

.read-more {
  font-size: var(--font-med-l);
}
.PostContentCaption {
  font-size: var(--font-med-s);
  line-height: 2.2rem;
  overflow-wrap: break-word;
  overflow: auto;
  color: #707070;
  margin: 0;
}
.QuotePost {
  border: var(--border);
  padding: 0.833rem;
  margin-top: 0.833rem;
}

.QuoteProfilePic {
  width: 2.67rem;
  height: 2.67rem;
  border-radius: 50%;
}

.QuotePostHeader {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.QuotePostInfo {
  margin-left: 0.83rem;
}

.PostImage {
  max-width: 100%;
  object-fit: contain;
  max-height: 50rem;
}

.PostImage-sm {
  max-width: 100%;
  object-fit: contain;
  max-height: 25rem;
}
.QuotePostAuthor {
  font-size: 1.16rem;
}

.QuotePostUsername {
  font-size: var(--font-sm);
  color: #657786;
}

.QuotePostDetails {
  font-size: var(--font-sm);
  color: #657786;
}

.TradeDetails {
  margin-top: 0.833rem;
  padding: 0.833rem;
  border-radius: 0.416remm;
}

.TradeHeader {
  font-family: 'Inter Display Bold';
  margin-bottom: 0.416rem;
}

.TradeInfo {
  display: flex;
  flex-wrap: wrap;
}

.TradeItem {
  width: 50%;
  margin-bottom: 0.416rem;
}

.TradeLabel {
  font-family: 'Inter Display Bold';
}

.CopyTradeButton {
  background-color: #1da1f2;
  color: white;
  border: none;
  padding: 0.416rem 0.833rem;
  border-radius: 0.416rem;
  cursor: pointer;
}

.PostContent {
  white-space: pre-wrap;
  line-height: 1.5rem;
}
.PostContentH2 {
  text-align: left;
  margin: 1rem 0 0 0;
  padding: 0;
  line-height: 2.4rem;
  font-size: var(--font-lg);
}
.CopyTradeButton:hover {
  background-color: #0d95e8;
}

.PostActions {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;
  font-size: 1.1rem;
}

.LeftActions {
  display: flex;
  font-size: var(--font-med-s);
  width: max(30%, 25rem);
  justify-content: flex-start;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  flex-wrap: wrap;
}

.ActionItem {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-med-s);
  height: 2rem;
  gap: 0.5rem;
}

.ActionItem object {
  max-height: 1.3rem;
  max-width: 2rem;
  object-fit: contain;
  width: 100%; /* Ensures the SVG scales to the parent */
  height: 100%; /* Ensures the SVG scales to the parent */
  object-fit: contain;
  pointer-events: none;
}

.ActionIcon {
  max-height: 1.3rem;
  max-width: 2rem;
  object-fit: contain;
}

.ActionItem2 object {
  max-height: 1.3rem;
  max-width: 2rem;
  object-fit: contain;
  width: 100%; /* Ensures the SVG scales to the parent */
  height: 100%; /* Ensures the SVG scales to the parent */
  object-fit: contain;
  pointer-events: none;
}

.ActionItem2 {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-med-s);
  width: 2rem; /* Explicit width */
  height: 2rem; /* Explicit height */
}

.UpvoteDownvoteContainer span {
  font-size: var(--font-med-s);
}

.BookmarkIcon.Bookmarked {
  color: var(--stock-change-pos);
}

.RightActions {
  display: flex;
  color: var(--action-grey);
  align-items: center;
  font-size: var(--font-med-s);
  flex-wrap: wrap;
  gap: 2.25rem;
}

/* Post.css */
.RepostModalContainer {
  position: relative;
}

.RepostModal {
  position: absolute;
  left: 6rem;
  background: white;
  border: 1px solid #e1e8ed;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: var(--font-med-l);
  padding: 0.5rem;
}

.RepostModal input {
  background: none;
  font-size: var(--font-med-l);
  border: 1px solid #e1e8ed;
  border-radius: 0.5rem;
  height: 1.5rem;
  padding: 0 0.25rem;
  width: 20%;
}

.RepostModal button {
  display: block;
  width: 100%;
  border: none;
  background: none;
  padding: 0.5rem;
  cursor: pointer;
  text-align: left;
  font-size: var(--font-med-l);
}
.RepostModal button:hover {
  background-color: #f5f8fa;
}

.RepostModalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.UpvoteDownvoteContainer {
  display: flex;
  color: #707070;
  justify-content: space-between;
  align-items: center;
  width: 7.25rem;
}

.RepostModal label {
  font-size: var(--font-med-l);
  height: 1.5rem;
}

.PostTradeContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  text-align: left;
  border-radius: 0.5rem;
  overflow: auto;
  margin-top: 1.33rem;
}

.PostTradeHeader {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.PostTradeDate {
  font-size: var(--font-med-l);
  opacity: 0.7;
}

.PostTradeType {
  font-size: var(--font-med-l);
}

.PostTradeContentContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  justify-content: space-between;
}

.PostTradeLabelAndValue {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 9rem;
}

.PostTradeButtonsContainer {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.PostTradeButtonsContainer button {
  border-color: rgba(var(--text-color), 0.3) !important;
}

.PostTaggedAssets {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1rem 0;
}

/* Post.css */
.ReplyButton {
  background: transparent;
  border: none;
  font-size: var(--font-med-s);
  color: var(--close-button-color); /* Change to appropriate color */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
}

.CloseButton {
  color: var(--close-button-color); /* Change to appropriate color */
}

.ReplyContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  background-color: var(--background-color);
  padding: 1rem 0.5rem;
  border: var(--border);
  border-radius: 0.5rem;
  height: auto;
  overflow: auto;
  min-height: 3rem;
  flex-direction: column;
  padding: 2rem 2rem;
}

.ReplyInput::placeholder {
  font-size: var(--font-med-l);
  color: var(--input-text-color);
  font-family: 'Inter Display Bold';
}

.ReplyInput {
  width: 100%;
  color: var(--text-color);
  font-size: var(--font-med-l);
  border: none;
  overflow: visible;
  background-color: transparent;
  padding: 0;
  resize: none;
  line-height: 2.2rem;
}

.ReplyActions {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.ReplyActionButton {
  color: var(--text-color);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: var(--font-med-s);
  background-color: var(--background-color-feed-btn);
  padding: 1rem 2.5rem;
  border-radius: 2rem;
  max-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'SF Pro Display Bold', sans-serif !important;
}

.ReplyPostButton {
  background-color: var(--button-green);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: var(--font-med-s);
  cursor: pointer;
}

.ReplyPostButton:hover {
  background-color: var(--button-green-hover);
}

@media screen and (max-width: 768px) {
  .MobileLoadingSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: var(--background-color, #f8f9fa);
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s ease-in-out; /* Smooth fade-out */
  }

  .MobileLoadingSpinner.hidden {
    opacity: 0; /* Fade out */
    pointer-events: none; /* Disable interactions */
  }
}
