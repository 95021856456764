@import url('variables.css');

.se-page-container {
  display: flex;
  justify-content: flex-start;
  max-width: var(--content-width);
  margin: 0 auto; /* Center-align the container */
  padding: 0 3rem;
  margin-top: 2rem;
}

.se-settings-container {
  width: 80%;

  margin-right: auto;
  font-family: Arial, sans-serif;
  color: var(--text-color);
  text-align: left;
}

.se-settings-title {
  font-size: 2.25rem !important;
  font-weight: bold;
}
.se-section {
  background-color: var(--settings-bg);
  padding: 1.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
}

.se-sections-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.se-section-title {
  font-size: var(--font-med-l);
  font-weight: bold;
  margin: 0rem;
}

.se-info-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-med-l);
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.se-info-grid-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-med-l);
  flex-direction: row;
  align-items: flex-start;
  gap: 2rem;
}

.se-col {
  display: flex;
  flex-direction: column;
}

.se-info-item {
  flex: 1;
}

.se-info-label {
  display: block;
  color: var(--action-grey);
  margin-bottom: 0.5rem;
}

.se-info-value {
  color: var(--text-color);
  background-color: var(--background-color-input);
  border: none;
  font-size: var(--font-med-l);
}

.se-checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.42rem;
}

.se-link-button {
  height: 2.55rem;
  background-color: var(--background-color);
  color: var(--text-color);
  border: var(--border);
  cursor: pointer;
  font-size: var(--font-sm);
  width: 19.7rem;
  margin: 0;
}

.se-subscription-details {
  font-size: var(--font-med-l);
  color: var(--text-color);
  margin: 0;
}

.se-sub-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
}

.se-policies {
  font-size: var(--font-med-l);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 3rem;
}

.se-terms-link {
  color: var(--stock-change-pos);
  text-decoration: underline;
  line-height: 2.2rem;
  font-size: var(--font-med-l);
  cursor: pointer;
}

.se-pin-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Toggle Container */
.se-toggle-container {
  display: flex;
  border: 0.5px solid var(--text-color); /* Use a color variable for dynamic theming */
  padding: 0.25rem;
  border-radius: 2rem;
  overflow: hidden;
  cursor: pointer;
  width: 16rem; /* Adjust width as needed */
  height: 3.5rem;
  background-color: transparent;
  position: relative;
}

/* Toggle Options */
.se-toggle-option {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-med-l);
  color: var(--text-color);
  transition: color 0.3s;
  border-radius: 2rem;
}

.se-settings-main-btns {
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
}

/* Active Toggle Background */
.se-toggle-option.active {
  background-color: var(
    --stock-change-pos
  ); /* Use a color variable or specific color */
  color: black;
}

/* Default Color for Inactive Option */
.se-toggle-option:not(.active) {
  color: var(--text-color);
}

.se-info-value.editable {
  width: 100%; /* Full width for better usability */
  padding: 0.5rem; /* Add padding for better spacing */
  font-size: var(--font-med-l);
  border: 1.5px solid #ccc; /* Light gray border */
  border-radius: 10px; /* Rounded corners for a modern look */
  background-color: #f9f9f9; /* Light background color */
  color: #333; /* Dark text for readability */
  outline: none; /* Remove the default outline */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */

  /* Ensure it integrates well with dark mode if needed */
  background-color: var(
    --background-color,
    #f9f9f9
  ); /* Use a CSS variable for dynamic themes */
  color: var(--text-color, #333);
}

/* Focus effect */
.se-info-value.editable:focus {
  border-color: #4caf50; /* Green border to indicate focus */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); /* Subtle shadow to highlight focus */
}

/* Disabled state */
.se-info-value.editable:disabled {
  background-color: #e9e9e9; /* Light gray background */
  border-color: #ddd; /* Gray border */
  color: #999; /* Gray text */
  cursor: not-allowed; /* Indicate disabled state */
}
