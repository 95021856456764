@import url('variables.css');

.NewsFeed {
    padding:var(--main-padding);
    padding-top: 2rem;
    display: flex;
    text-align: left;
}

.NewsPosts {
    width: 80%;
    padding-right: 4rem;
    display: flex;
    justify-content: space-between;
}

.NewsSection1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.SummarizedStories {
    width: 20%;
    background-color: var(--color-main);
    text-align: left;
    font-size: var(--font-med-s);
    
}

.SummarizedStoryTitle {
    margin:0rem;
    line-height: 1.93rem;
}

.NewsSecondaryPostColumn .NewsPostSecondary:first-child {
    padding-top: 0;
}



.SummarizedStoriesList {
    list-style: none;
    padding: 0;
}

.SummarizedStoryItem {
    border-bottom: var(--border);
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
}
.RelatedNewsText {
    font-size: var(--font-med-xl);
    margin-bottom: 2rem ;
    color: #c6c6c6;
}


.SummarizedStoryTime {
    font-size: 0.875rem;
    color: #888;
    margin: 0.25rem 0 0;
}


/* News Post styles*/

.NewsPostMainContent, .NewsPostSecondaryContent, .NewsPostTertiaryContent {
    font-size: var(--font-med-s);
    line-height: 1.93rem;
}

.NewsPostTitle {
    font-size: var(--font-xl);
}

.NewsPostMain {
    margin-bottom: 3rem;
}



.NewsPostMainSecondary {
    padding-bottom: 5rem;
}

.NewsPostMainSecondaryContent {
    margin-top: 0;
}


.NewsPostMainSecondaryCol {
    padding-right: 1rem;
}



.NewsMainPostColumn {
    width: calc(50% - 2rem);
}

.NewsSecondaryPostColumn {
    width: calc(50%  - 2rem);
}

.NewsPostSecondary {
    padding: 2rem 0;
}



.NewsSecondaryPostColumn .NewsPostSecondary:first-child .NewsPostSecondaryTitle {
    margin-top: 0;
    font-size: var(--font-lg);
}
.NewsSecondaryPostColumn .NewsPostSecondaryTitle {
    margin-top: 0;
    margin-bottom: 0;
}

.SummarizedStoriesList .SummarizedStoryItem:first-child  {
    padding-top: 0;
}

.NewsPostTertiary {
    width: calc(50% - 1rem);

}

.RelatedText {
    font-size: var(--font-med-s);
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #c6c6c6;

}


.NewsPostTertiaryImage {
    max-height: 20rem;
    object-fit: cover;
    max-width: 100%;
}

.NewsSecondaryPostColumn .NewsPostMainSecondary:nth-of-type(2) {
    /* Your styles here */
    padding-top: 1rem;
}

.NewsPostMainImage {
    width: 100%;
    height: 30rem;
    object-fit:cover;
}

.NewsPostMainSecondaryImage {
    object-fit: cover;
    width: 40%;
    height: 15rem;

}



.NewsPostMainSecondaryTitle {
    margin-top: 0;
}


.NewsTertiaryRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.NewsPostMainSource, .NewsPostSecondarySource, .NewsPostTertiarySource, .SummarizedStoryTime {
    font-size: var(--font-sm);
    color: var(--action-grey);
    line-height: 1.5rem;
}