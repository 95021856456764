@import url('variables.css');

.ov-13 {
  font-size: 1.08rem;
  line-height: 1.67rem;
}

.ov-15{
  font-size: var(--font-med-s);
  line-height: 1.93rem;
}

.ov-16 {
  font-size: var(--font-med-l);
  line-height: 2.2rem;
}

.ov-20 {
  font-size: 1.74rem;
  line-height: 2.2rem;
}

.ov-24 {
  font-size: 2.08rem;
  line-height: 2.5rem;
} 

.ov-32 {
  font-size: 2.78rem;
}

.ov-overview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.33rem;
    max-width: 80%;
    margin: 5rem auto 0;
    margin-top: 5rem;
    margin-bottom: 15rem;
  }

  .Overview {
    position: relative;
  }

  .ov-mrvestr-inputs {
    display: flex;
    align-items: center;
    gap: 1rem; /* Adjust gap between input box and button */
  }
  

  

  .ov-overview-cloud-container {
    position: absolute;
    top: -20rem;
    left: 0;
    max-width: 70rem;
    z-index: -1;
    
  }

  .ov-overview-cloud {
    width: 100%;
    height: 100%;
    object-fit: contain;
  
  }


  
  .ov-vestr-logo {
    font-size: 11rem; 
    background: linear-gradient(to right, #1DCF5B, #62FF99);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
  }
  
  .ov-question-box  { 
    border: 0.083rem solid #1DCF5B; /* 1px green border */
    border-radius: 4.167rem; /* 50px fully rounded corners */
    padding: 2rem 20rem;
    margin-bottom: 2rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .ov-ask-button {
    background: linear-gradient(to right, #1DCF5B, #62FF99);
    color: #fff;
    border: none;
    margin-bottom: 2rem;
    height: 2.5rem;
    width: 2.5rem;
    padding: 2.083rem;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    font-size: 3.7rem;
    color: #fff;
    border-radius: 50%;
  
  }

  
  .ov-overview-subtitle {
    font-size: 1.7rem;
    color: var(--text-color);
    margin: 0;
    height: 2rem;
  }
  
  .ov-questions-container {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 1rem;
    width: 100%;
    max-width: 80rem; 
    justify-items: center; 
  }
  .ov-question-button {
    width: 100%; 
    max-width: 50rem; 
    height: 5rem;
    background-color: var(--ov-cont-color);
    border: none;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    color: var(--text-color);
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
    line-height: 1.67rem; 
    font-weight: normal;
  }
  .ov-question-button:hover {
    background-color: #e0e0e0;
  }
  
  .ov-introduction-container,
  .ov-data-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.33rem;
    max-width: 80%;
    margin: 5rem auto 0;
    margin-bottom: 15rem;
  }
  

  .ov-introduction-container {
    margin-bottom: -3rem;
    padding-bottom: 0rem ;
  }


  
  .ov-introduction-text {
    flex: 1;
    text-align: left;
    align-self: flex-start;
    max-width: 25rem;
  }
  
  .ov-introduction-title {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  
  .ov-introduction-divider {
    position: relative;
    border-bottom: var(--border);
    margin: 1rem 0;
    width: calc(100% + 8rem);
    border-color: var(--text-color) !important;
  }
  
  .ov-introduction-subtitle {
    margin-bottom: 1rem;
  }
  
  .ov-introduction-description {
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  
  .ov-introduction-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .ov-introduction-image img {
    max-width: 100%;
    height: auto;
  }
  
  /* Data section below the laptop image */
  .ov-data-section {
    display: flex;
    justify-content: space-between;
    align-items: stretch; /* Ensure all cards stretch to the same height */
    max-width: 80%;
    margin: 5rem auto 0;
    margin-bottom: 15rem;
    margin-top: 3rem; /* Manual adjusment */
  }
  
  .ov-data-card {
    background-color: var(--ov-cont-color);
    border-radius: 3rem;
    padding: 2rem;
    width: calc(35.8333% - 4rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .ov-card-header {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-bottom: 1rem;

  }
  
  .ov-card-button {
    background-color: #fff;
    border: none;
    border-radius: 0.5rem 0.5rem 0 0.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    min-width: 20rem;
    text-align: left;
    
    
  }
  
  .ov-card-avatar {
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
  }

  .ov-color {
    width: 1rem;
    height: 1rem;
  }

  .ov-colors {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  
  .ov-card-content {
    text-align: left;
    background-color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 0.8rem 0.8rem 0.8rem 0;
    height: 40rem;
    color:#000;
    
  }
  
  .ov-graph-image {
    max-width: 100%;
    margin: 1rem 0;
  }
  
  .ov-info-card {
    text-align: left;
    white-space: prewrap;
    border-radius: 1rem;
    width: 25%;
    color: var(--text-color);
  }
  
  .ov-info-text {
    line-height: 1.5;
    color: var(--text-color);
  }

  .ov-investors-section {
    display: flex;
    padding: 3.33rem;
    max-width: 80%;
    margin: 5rem auto 0;
    margin-bottom: 15rem;
    margin-top: 3rem; /* Manual adjusment */;
  }
  .ov-top-investors {
    width: calc(74.5% - 4rem);
    display: flex;
    flex-direction: column;
    background-color: var(--ov-cont-color);
    border-radius: 2.5rem;
    padding: 2rem;

  }

  .ov-row-investors {
    display: flex;
    justify-content: space-between;
  }
  
  .ov-investor-pic {
    border-radius: 50%;
    height:55%;
    object-fit: contain;

  }

  .ov-number {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFBF19;
  }

  .ov-top-text {
    text-align: left;
    margin-top:0;
  }

  .ov-invest-text {
    width: calc(24.5% - 4rem);
    text-align: left;
    padding: 2rem;
  }

  .ov-investor {
    display: flex;
    flex-direction: row;
    width: 33%;
  }

  .ov-investor-col1, .ov-investor-col2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: space-between;
    height: 90%;
  }

  .ov-investor-col2 h3 {
    line-height: normal;
  }

  .ov-investor-col2-stats {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30%;

  }

  .ov-investor-col2-stats-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height:20%;
  }

  .ov-investor-col1 {
    position:relative;
  }

  .ov-badge-row {
    display: flex;
    justify-content: space-between;
  }


.ov-investor-col2 {
    text-align: left;
    color: #333;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1rem;
}

.ov-investor-col2 h3 {
    font-weight: bold;
    margin:0;
}

.ov-investor-col2 p {
    margin: 0rem;
}

.ov-investor-stat {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.ov-green {
    color: #48BB78; 
}

.ov-stat-label {
    font-weight: normal;
    color: #666;
}

.ov-investor-details {
    color: var(--text-color);
    margin-bottom: 0.5rem;
}

.ov-investor-positions {
    color: var(--text-color);
}

.ov-follow-btn {
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 1.67rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.ov-portfolio-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.33rem;
    max-width: 80%;
    margin: 5rem auto 0;
    height: 40rem;
    margin-bottom: 15rem;
}


.ov-portfolios-row {
    display: flex;
    width: calc(47% - 4rem);
    height: 100%;
    background-color: var(--background-color);
    border-radius: 2.5rem;
    padding: 2rem;
}
.ov-portfolio-col1, .ov-portfolio-col2 {
    display: flex;
    flex-direction: column;
    width: 55%;
    height: 100%;
}

.ov-portfolio-col1, .ov-portfolio-col2 {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
}

.ov-portfolio-text {
  display : flex; 
  flex-direction: column;
  justify-content: space-between;

}
.ov-portfolio-text h3 {
  margin: 0;
  text-align: left;
}
.ov-portfolio-entry {
  display: flex;
  gap: 1.5rem;
  width: 100%;
}


.ov-portfolio-img {
    height: 4.5rem;
    object-fit: contain;
    border-radius: 2.5rem;
}

.ov-portfolios-info {
  display: flex;
  flex-direction: column;
  width: 22%;
  height: 100%;
  padding: 2rem 0;
  border-radius: 2.5rem;
  text-align: left;
}

.ov-portfolios-recent {
  display: flex;
  flex-direction: column;
  width: calc(28% - 4rem);
  height: 100%;
  background-color: var(--background-color);
  border-radius: 2.5rem;
  padding: 2rem;
  gap: 1rem;
  text-align: left;
}

.ov-portfolios-recent h2 {
  margin-bottom: 1rem;
  font-weight: bold;
}

.ov-trade-card {
  background-color: #fff;
  border-radius: 0.416rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; 
}

.ov-red-outline {
  border: 0.1px solid #CA222C; 
}

.ov-green-outline {
  border: 0.1px solid #1DCF5B;
}

.ov-trade-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.ov-trade-type {
  font-weight: bold;
}

.ov-trade-time {
  color: #B3B3B3;
}

.ov-trade-body {
  display: flex;
  flex-direction: column;
}

.ov-trade-row {
  display: flex;
  justify-content: space-between;

}

.ov-trade-label {
  width: 6.5rem;
  white-space: nowrap;
  color: #B3B3B3;
  text-align: center;
}

.ov-trade-value {
  width: 6.5rem;
  text-align: center;
}

.green-background {
  background-color: #C8E0D0;
}

.red-background {
  background-color: #FDE8E8;
}

.green-text {
  color: #48BB78;
}

.red-text {
  color: #F56565;
}

.ov-green {
  color: #1DCF5B;
}

.ov-red {
  color: #CA222C;
}

.ov-spotify-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3.33rem;
  max-width: 80%;
  margin: 5rem auto 0;
  height: 30rem;
  margin-bottom: 15rem;
  margin-top: 3rem; /* Manual adjusment */
}


.ov-spotify-stat span {
  color:#B3B3B3;
}

.ov-spotify-info {
  width: calc(24.25% - 4rem);
  height: 100%;
  background-color: var(--ov-cont-color);
  border-radius: 2.5rem;
  padding: 2rem;
}


.ov-spotify-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.ov-spotify-logo {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
}

.ov-spotify-details {
  margin-left: 1rem;
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
  gap: 0.25rem;
}

.ov-spotify-details h3 {
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
}

.ov-verified {
  color: #1DCF5B;
  font-size: var(--font-med-s);
  margin-left: 0.35rem;
  margin-top: 0.1rem;
}

.ov-spotify-details p {
  margin: 0;
  color: #666;
}

.ov-spotify-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.ov-spotify-joined-btn, .ov-spotify-audio-btn {
  border-radius: 2rem;
  border: none;
  cursor: pointer;
}

.ov-spotify-joined-btn {
  padding: 0.25rem 2rem;
  background-color: #fff;
  color: #000;
}

.ov-spotify-audio-btn {
  padding: 0.25rem 3rem;
  background-color: #000;
  color: #fff;
}

.ov-spotify-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.ov-spotify-stat h4 {
  margin: 0;
}

.ov-spotify-stat p {
  margin: 0;
  color: #666;
}

.ov-divider-spo {
  border: 0.25px solid #ccc;
  margin: 1rem 0;
  width: calc(100% + 3.75rem);
  margin-left: -2rem;
}

.ov-room-rankings h4 {
  margin-bottom: 1rem;
}

.ov-room-rankings ul li {
  display: flex;
  align-items: center;
  gap:1rem;
}

.ov-room-rankings ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ov-room-rankings li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.ov-rank-number {
  margin-right: 0.5rem;
  color: #B3B3B3;
  font-size:1.17rem;
}

.ov-rank-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}


.ov-spotify-comments {
  width: calc(74.25% - 4rem);
  background-color: var(--ov-cont-color);  
  border-radius: 2.5rem;
  padding: 2rem;
  height:100%;
  
}

.ov-comment-card {
  border-radius: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.ov-comment-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

}

.ov-comment-avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.ov-comment-details {
  flex-grow: 1;
  margin-left: 1rem;
  text-align: left;
}

.ov-comment-details h4 {
  margin: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.ov-comment-details h4 span {
  font-weight: normal;
  color: #666;
  margin-left: 0.5rem;
}

.ov-comment-details p {
  margin: 0.5rem 0;
  line-height: 1.5;
}

.ov-comment-replies {
  color: var(--text-color);
  margin-top: 0.5rem;
}

.ov-comment-content {
  width: 95%;
}

.ov-comment-votes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ov-vote-up-green {
  cursor: pointer;
  color: #1DCF5B;
}

.ov-vote-up, .ov-vote-down {
  color: #888;
  cursor: pointer;
}

.ov-vote-count {
  font-weight: bold;
  margin: 0.25rem 0;
  color: #888;
}



.ov-highlight {
  color: #1DCF5B; 
  font-weight: bold;
}

.ov-gme-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.33rem;
  max-width: 80%;
  margin: 5rem auto 0;
  height: 25rem;
  margin-bottom: 15rem;
}

.ov-gme-post {
  width: calc(74.25% - 4rem);
  background-color: var(--ov-cont-color);
  border-radius: 2.5rem;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.ov-gme-post-pic {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  align-self: flex-start;
}

.ov-gme-post-text {
  width: calc(24.25%);
  border-radius: 2.5rem;
  height: 100%;
  padding: 2rem 0;
  text-align: left;  
}

.ov-gme-post-content {
  text-align: left; 
  margin: 0;
  align-self: flex-start;
}

.ov-gme-post-header {
  margin-left: 1rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ov-gme-post-details {
  display: flex;
  align-items: center;
  width: calc(100% - 6rem);
  padding: 0rem 1.5rem;
  border-radius: 0.416rem;
  margin-bottom: 0.25rem;
  flex-direction: column;
  margin-left:6rem;
  margin-bottom: 1rem;
}


.ov-gme-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.ov-gme-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 2rem;
  height: 100%;
  gap: 0.5rem;
  text-align: left;
  width: 20%;
}

.ov-gme-label {
  color: #b3b3b3;
}

.ov-gme-value {
  font-weight: bold;
}

.ov-gme-green {
  color: #1DCF5B; 
}

.ov-gme-copy-trade-btn {
  background-color: transparent;
  color: var(--text-color);
  border: 0.5px solid var(--text-color);
  border-radius: 1.67rem;
  padding: 0.5rem 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
  white-space: nowrap;
}
.ov-gme-action-items {
  display: flex;
  justify-content: space-between;
  margin-left: 6rem;
  width:100%;
}

.ov-LeftActions {
  display: flex;
  gap: 3.5rem;
  color: #707070;
} 
.ov-RightActions{
  display: flex;
  gap: 1.25rem;
}

.ov-bookmarked {
  color: #1DCF5B;
}


.ov-gme-verified {
  color: #1DCF5B;
}

.ov-news-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.33rem;
  max-width: 80%;
  margin: 5rem auto 0;
  height: 55rem;
  margin-bottom: 15rem;
}

.ov-news-publisher {
  color: #707070;
}



.ov-news-card {
  width: calc(32.3333% - 4rem);
  background-color: var(--ov-cont-color);
  border-radius: 2.5rem;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
} 

.ov-news-stock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.ov-news-img {
  width: 100%;
  height: 10rem;
  border-radius: 1rem;
}

.ov-news-header{
  width: 100%;
  text-align: left;
  margin: 0.75rem 0; 
  height: 5rem;
}

.ov-news-description {
  text-align: left; 
  height:8rem;
}

.ov-news-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}

.ov-news-action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap:0.25rem;
  color: #707070;
}
.ov-market-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.33rem;
  max-width: 80%;
  margin: 5rem auto 0;
  height: 32rem;
  margin-bottom: 15rem;


}

.ov-market-public {
  width: calc(62.25% - 4rem);
  background-color: var(--ov-cont-color);
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  height: 100%;


}

.ov-market-analysis {
  width: calc(36.25% - 4rem);
  background-color: var(--ov-cont-color);
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
}


.ov-market-public-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ov-market-analysis-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
} 

.ov-market-public-col1, .ov-market-public-col2 {
  display: flex;
  flex-direction: column;
  width: 50%;
} 

.ov-market-col-btn {
  background-color: transparent;
  border: 0.5px solid var(--text-color);
  border-radius: 1.67rem;
  padding: 0 1rem;
  width: calc(40% - 2rem);
  height:3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--text-color);
}
.ov-market-col-graph {
  width:100%;
}

.ov-market-analysis-col1 {
  display: flex;
  flex-direction: column;
  width: 75%;
  place-items: center;
  justify-content: center;
}

.ov-market-analysis-color {
  width: 1.25rem;
  height: 1.25rem;
  align-self: center;
}



.ov-market-analysis-col2 {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;  
  justify-content: space-between; 
}

.ov-market-analysis-wheel {
  width: 90%;
  margin-left: -4.5rem;
}

.ov-market-analysis-buy {
  display: flex;
  gap: 0.5rem;  
}

.ov-market-analysis-buy span {
  white-space: nowrap;
  overflow: hidden; 
}

.ov-subscription-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.33rem;
  max-width: 80%;
  margin: 5rem auto 0;
  height: 52rem;
  margin-bottom: 15rem;
}

.ov-subscription-rating {
  display: flex;
  align-items: center;
}

.ov-subscription-reviews {
  color:#707070;
  margin-left: 1rem;
}

.ov-subscription-col1 {
  display: flex;
  flex-direction: column;
  gap:3rem;
  width: calc(32.25% - 4rem);
  background-color: var(--ov-cont-color);
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
}

.ov-subscription-col2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 66.25%;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: calc(100% + 4rem);
}

.ov-subscription-col2-row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32.75%;
}



.ov-subscription-c2-r1-1 {
  width: calc(53.9% - 4rem);
  padding: 2rem;
  height: calc(100% - 4rem);
  background-color: var(--ov-cont-color);
  border-radius:2.5rem;

}
.ov-subscription-c2-r1-2 {
  width: calc(43.9% - 4rem);
  padding: 2rem;
  height: calc(100% - 4rem);
  background-color:var(--ov-cont-color);
  border-radius:2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



.ov-subscription-col2-row2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 4rem);
  height: calc(64.25% - 4rem);
  background-color: var(--ov-cont-color);
  border-radius:2.5rem;
  padding: 2rem;
  flex-direction: column;
}



.ov-subscription-features {
  text-align: left;
  align-self: flex-start;
  margin: 0.5rem 0 0.5rem 0;
  padding-inline-start: 2rem;
}

.ov-subscription-details {
  display: flex;
   justify-content: space-between; 
   align-items: center;
}

.ov-subscription-button {
  align-self: flex-start;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 1.67rem;
  padding: 0.5rem 2rem; 
  cursor: pointer;
}

.ov-subscription-stars {
  color: #1DCF5B;
}


.ov-badge {
  width: 30%;
  height: 100%;
}

.ov-award {
  width: 20%;
}


.ov-subscription-awards {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ov-subscription-c2-r1-2 p {
  margin: 0 0 0.25rem 0;
}

.ov-subscription-chart {
  max-width: 27.5rem;
  max-height: 27.5rem;

}

.ov-subscription-portfolio-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 4rem);
}
.ov-subscription-allocate {
  
  display: flex;
  align-items: flex;
  flex-direction: column;
  align-self: flex-start;
  height: 100%;
  gap: 0.5rem;
}

.ov-subscription-allocate h3 {
  margin: 0;
}

.ov-subscription-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.ov-subscription-portfolio-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;

}

.ov-final-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3.33rem;
  max-width: 80%;
  margin: 5rem auto 0;
  height: fit-content;  
  gap:2rem;
  margin-bottom: 15rem;
}

.ov-final-container h2 {
  margin: 0;
  font-size: 4.5rem;
  width:25rem;
}
.ov-final-image {
  width:50%;
  margin-left: -3.5rem;
}

