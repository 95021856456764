@import url('variables.css');

.terms-container {
    text-align: left;
    margin : 0rem auto;
    padding: 3rem 3rem;
    font-size: var(--font-med-l);
    line-height: 2.2rem;
    max-width: var(--content-width);
}

.terms-subtitle {
    margin-bottom: 0rem;
    font-size: var(--font-med-l);
}

.terms-list {
    margin-top: 0rem;

}

.terms-title {
    margin-top: 0;
}