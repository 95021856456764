@import url('variables.css');

.mv-mr-vestr {
  width: 100vw; 
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden; 
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.mr-vestr-frame {
  width: 100%;
  height: 100%;
  border: none;
}

.blurred {
  filter: blur(5px);
  pointer-events: none; 
}
