.dt-data-table-container {
  padding: 1rem;
}

.dt-data-table {
  width: 100%;
  border-collapse: collapse;
}

.dt-data-table-header {
  background-color: #f4f4f4;
}

.dt-data-table-header-cell {
  text-align: center;
  padding: 0.75rem; /* = 8px */
  border-bottom: 0.09rem solid #ddd; /* = 1px */
}

.dt-data-table-body {
  background-color: white;
}

.dt-data-table-row {
  transition: background-color 0.2s ease;
}

.dt-data-table-row:hover {
  background-color: #f9f9f9;
}

.dt-data-table-cell {
  padding: 0.75rem; /* = 8px */
  border-bottom: 0.09rem solid #ddd; /* = 1px */
}

.dt-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.dt-pagination-button {
  padding: 0.55rem 1rem; /* = 6px 11px */
  border: 0.09rem solid #ccc; /* = 1px */
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dt-pagination-button:hover {
  background-color: #e0e0e0;
}

.dt-pagination-button:disabled {
  background-color: #dcdcdc;
  cursor: not-allowed;
}

.dt-pagination-info {
  font-size: 1rem; /* = 11px */
}