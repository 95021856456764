.NewsContainerV2 {
    max-width:var(--content-width); 
    margin: 0 auto; /* Center-align the container */
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
}

.ne-filter-button {
    background-color: transparent;
    border: none;
    color: var(--text-color);
    width: 10rem;
    cursor: pointer;
    border-left: var(--border);
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    margin-right:-2rem;
    font-size: var(--font-med-s);
}

.ne-FilterDropdown {
    display: flex;
    justify-content: space-between;
    background-color: var(--main-color);
    border: var(--border);
    border-radius: 0 0 0.5rem 0.5rem;
    width: calc(100% - 3rem);
    padding: 1.5rem;
    position: absolute;
    top: 100%; /* Positions the dropdown right below the search bar */
    left: 0;
    z-index: var(--z-index-low);
    margin-left: -0.05rem;
    
}

.ne-FilterCategory {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ne-FilterCategoryTitle {
    font-size: var(--font-med-l);
    color: var(--text-color);
    margin-bottom: 1rem;
}

.ne-FilterOptions {
    display: grid;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.ne-FilterOption {
    background-color: transparent;
    border: var(--border);
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
    font-size: var(--font-med-l);
    color: var(--text-color);
    cursor: pointer;
}

.ne-FilterOption.ne-active {
    background-color: var(--stock-change-pos);
    color: black;
}

/* Specific grid layouts for each filter category */
.ne-FilterOptions.ne-style {
    grid-template-columns: repeat(2, 1fr); /* Two buttons per row */
}

.ne-FilterOptions.ne-length {
    grid-template-columns: repeat(2, 1fr); /* One row with two buttons */
}

.ne-FilterOptions.ne-timeframe {
    grid-template-columns: repeat(2, 1fr); /* Two buttons per row */
}

.ne-FilterOptions.ne-sources {
    grid-template-columns: repeat(1, 1fr); /* Two rows with one button each */
}

.ne-main-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.ne-left-sidebar, .ne-right-sidebar {
    width: 50%;
    padding: 1.5rem;
    padding-top: 0rem;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.ne-left-sidebar {
    border-right: var(--border);
    padding-right:3rem;
    padding-left: 0;
}

.ne-right-sidebar {
    padding-left: 3rem;
    padding-right: 0;
}

.ne-daily-quote {
    font-size: var(--font-med-l);
    margin-top:2rem;
    margin-bottom: 2rem;
    line-height: 2.2rem;
}

.ne-date {
    color: var(--action-grey);
    font-size: var(--font-lg);
    margin: 0;
}

.ne-greeting {
    font-size: var(--font-xl);
    margin-top: 1rem;
}


.ne-buttons-row {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.ne-news-story {
    border-bottom: var(--border);
}

.ne-news-story h2 {
    margin: 0;
    font-size: var(--font-med-l);
}

.ne-news-story p {
    font-size: var(--font-med-l);
    color: var(--action-grey);
    line-height: 2.2rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}


.ne-news-story-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.ne-news-story-wrapper .ne-news-story:last-child {
    border-bottom: none;
}

.ne-explore-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
}

.ne-explore-card {
    width: calc(31% - 2.57rem);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;
    height: 8rem;
    overflow: auto;
    background-color: var(--background-color);
    cursor: pointer;
}
.ne-explore-card h3 {
    font-size: var(--font-med-l);
    margin: 0;
}
.ne-explore-section-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.ne-main-news-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ne-main-news-description {
    font-size: var(--font-med-l);
    line-height: 2.2rem;
    margin-top: 1rem;
    color: var(--text-color);
}

.ne-main-news-description p, ul, h3 {
    margin: 1rem 0;
}

.ne-pagination {
    display: flex;
    align-items: center;
    gap: 1rem;

}

.ne-page-span {
    font-size: var(--font-med-s);
    color: var(--text-color);
}

.ne-title-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.ne-current-news-btns {
    display: flex;
    align-items: center;
    gap: 1rem;
    
}