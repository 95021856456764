@import url('variables.css');

.vp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    gap: 2rem;
    max-width: var(--content-width); 
    margin: 0 auto; /* Center-align the container */
    padding: 0 3rem;
    padding-bottom: 3rem;


  }

  .vp-header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    gap: 1rem;
    margin-top: 2rem;
  }

  .vp-header-container h2 {
    font-size: var(--font-xl);
    color: var(--text-color);
    margin: 0;
  }
  
  .vp-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  
  .vp-card {
    width: calc(30% - 2rem);
    height: 85rem;
    background-color: var(--settings-bg);  
    border-radius: 3rem;    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }

  .vp-off {
    position: absolute;
    top: -1rem;
    left: -1rem;;
    background-color: #CA222C;
    padding: 1rem 2rem;
    font-size: var(--font-med-xl);
    color: #fff;
    border-radius: 0.416rem;
  }
  
  .vp-image-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .vp-image-bg {
    position: absolute;
    width: 15.3rem; /* Adjust the width as necessary */
    height: auto;
  }
  
  .vp-image {
    position: relative;
    width: 15.3rem;
    margin-left: 0.5rem;
  }

  .vp-per {
    font-size: var(--font-med-xl);
  }
  
  .vp-button {
    width: 100%;
    padding: 1rem;
    background-color: var(--background-color);
    border: 0.5px solid #c6c6c6; 
    border-radius: 1.5rem;
    cursor: pointer;    
    font-size: var(--font-med-xl);
    color: var(--text-color);
  }
  
  .vp-type-title {
    font-size: 3rem;
    color: var(--text-color);
    margin: 0;
  }
  
  .vp-cost {
    font-size: 4rem;
    color: var(--text-color);
    margin: 0;
    position: relative; 
    width: 18rem;
  }

  .vp-discount {
    font-size: var(--font-xl);
    position: absolute;
    color: #C1C1C1;
    top: -2.5rem;
    left: -5.5rem;
  }

  .vp-discount::after {
    content: '';
    position: absolute;
    top: 50%; /* Center the line vertically */
    left: 0;
    width: 100%;
    height: 0.2rem; /* Set a specific height for the line */
    background-color: #C1C1C1; /* Use a simple color instead of an SVG */
    transform: rotate(15deg); 
  }
  .vp-discount-2 {
    font-size: var(--font-xl);
    position: absolute;
    color: #C1C1C1;
    top: -2.5rem;
    left: -5.5rem;
  }

  .vp-discount-2::after {
    content: '';
    position: absolute;
    top: 50%; /* Center the line vertically */
    left: 0;
    width: 100%;
    height: 0.2rem; /* Set a specific height for the line */
    background-color: #C1C1C1; /* Use a simple color instead of an SVG */
    transform: rotate(8deg); 
  }
  

  
  .vp-perks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;
  }
  .vp-perks span {
    font-size: var(--font-med-l);
    color: var(--text-color);
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
  }

  .vp-perks span {
    text-align: left;
  }

  .vp-fa {
    margin-right: 0.5rem;
  }
