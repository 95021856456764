@import url('variables.css');

.MarketsContainer {
  display: flex;
  flex-direction: column;
  max-width: var(--content-width);
  margin: 0 auto; /* Center-align the container */
  padding: 0 3rem;
}

.MarketsSearchWrapper {
  position: relative; /* Ensures suggestions are positioned relative to the wrapper */
  display: flex;
  align-items: center;
  margin: 2rem 0;
  background-color: var(--background-color);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  height: 3rem;
}

.MarketsSearchBar {
  width: 100%;
  color: var(--text-color);
  font-size: var(--font-med-xl);
  border: none;
  background-color: transparent;
}

.MarketsSearchBar::placeholder {
  color: var(--input-text-color);
}

.SuggestionsDropdown {
  position: absolute;
  top: 100%; /* Position dropdown right below the input */
  left: 0;
  width: 100%; /* Match width of search bar */
  background-color: var(--background-color);
  border: none;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  max-height: 40rem; /* Set a max height for scroll */
  overflow-y: auto;
  padding-inline-start: 0;
  text-align: left;
  font-size: var(--font-med-l);
  z-index: var(--z-index-med);
}

.SuggestionItem {
  padding: 1rem;
  font-size: var(--font-med-l);
  cursor: pointer;
  border-bottom: var(--border);
  color: var(--text-color);
}

.SuggestionItem:hover {
  background-color: var(--hover-color);
}

.SuggestionItem:last-child {
  border-bottom: none;
}

.MarketsListContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4rem;
  padding: 0;
  margin-top: 2rem;
  padding-bottom: 3rem;
}

.MarketsList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
}

.MarketsListHeader {
  font-size: var(--font-med-xl);
  margin-bottom: 1rem;
  text-align: left;
  margin-top: 0rem;
}

.ListActionButtons {
  display: flex;
  gap: 2rem;
}

.MarketListHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MarketsStockCardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.MarketsStockCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 0.75rem;
  width: 15.8rem;
  height: 15.8rem;
  cursor: pointer;
  overflow: auto;
}

.MarketsStockCard:hover {
  background-color: transparent !important;
}

.MarketsStockCardHeading {
  display: flex;
  flex-direction: column;
}

.MarketsStockCardFooting {
  display: flex;
  flex-direction: column;
}

.MarketTicker {
  font-size: var(--font-med-l);
  margin: 0;
  color: #b3b3b3;
}

.MarketName {
  font-size: var(--font-med-l);
  margin-top: 1rem;
}

.MarketItemPrice {
  font-size: var(--font-med-l);
}

.marginZeroFive {
  margin-top: 0.5rem;
}

.MarketItemChange {
  border-radius: 5px;
  font-size: var(--font-med-l);
  text-align: center;
  padding: 0.75rem 0;
  width: 50%;
}

.ma-positive {
  color: var(--ma-green);
}

.ma-negative {
  color: var(--ma-red);
}

/* Additional CSS adjustments for better layout */
.MarketsPriceChangeWrapper {
  display: flex;
  align-items: center;
  width: 15rem;
  justify-content: space-between;
}

.MarketsStockCardEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MarketsStockCardEmptyText {
  font-size: var(--font-med-xl);
}
