/* Toast.css */
.toastMessage {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px 24px;
  border: 2px solid transparent;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  z-index: 19000;
  animation: fade-in 0.3s ease-in-out, fade-out 0.5s ease-out 2.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toastMessage.success {
  border-color: #32cd32;
  background-color: rgba(50, 205, 50, 0.1);
  color: #32cd32;
}

.toastMessage.error {
  border-color: #e63946;
  background-color: rgba(230, 57, 70, 0.1);
  color: #e63946;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
