@import url('variables.css');

.port-container {
  display: flex;
  max-width: var(--content-width);
  margin: 0 auto; /* Center-align the container */
  padding: 2rem 3rem;
  flex-direction: column;
}

.port-left-sidebar {
  width: 18%;
  padding: 2rem 3rem;
  border-right: var(--border);
}

.port-main-content {
  padding-top: 0rem;
  display: flex;
  flex-direction: column;
}

.port-right-sidebar {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}

.port-right-sidebar-header {
  text-align: left;
  margin-top: 0rem;
  margin-bottom: 2rem;
  font-size: var(--font-lg);
}

.port-left {
  text-align: left;
}

.port-center {
  text-align: center;
}

.port-right {
  text-align: right;
}

.port-value {
  text-align: left;
  font-size: var(--font-xl);
}

.port-change {
  text-align: left;
}

.portfolio-trade-card {
  background-color: #e2f9ea;
  padding: 1.5rem 1.5rem;
}

.port-trade-history {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.port-main-content-header {
  text-align: left;
  margin: 0;
}

.port-header-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.port-stats-filter-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.port-stats-section {
  display: flex;
  width: 100%;
  gap: 5rem;
  font-size: var(--font-med-l);
  margin-bottom: 2rem;
}

.port-buys-sells-section,
.port-unrealized-section,
.port-stats-filter-section {
  text-align: left;
}

.port-stats-table {
  width: 100%;
  overflow: auto;
  margin-top: 2rem;
}

.port-stats-table th,
.port-stats-table td {
  padding-left: 0rem !important;
}

.stats-table {
  overflow: auto;
  width: 100%; /* Ensure the table takes up 100% width */
  border-collapse: collapse;
}

.stats-table th,
.stats-table td {
  text-align: left;
  border-bottom: var(--border);
  padding: 2rem;
  overflow: hidden;
}

.stats-table th {
  font-size: var(--font-med-l);
  color: var(--action-grey);
  font-family: 'SF Pro Display Bold', sans-serif !important;
  font-weight: 0 !important;
}

.stats-table td {
  font-size: var(--font-med-l);
  cursor: pointer;
}

.port-positive {
  color: var(--stock-change-pos);
}

.port-negative {
  color: var(--stock-change-neg);
}

.AddCashContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.AddCashInput {
  background-color: transparent;
  color: var(--text-color);
  padding: 1rem 2.5rem;
  font-size: var(--font-med-s);
  border-radius: 2rem;
  max-height: 3.5rem;
  border: var(--border);
  box-sizing: border-box;
}

.AddCashInput::placeholder {
  color: var(--placeholder-color);
  font-family: 'SF Pro Display Bold', sans-serif;
}

.PortTrade {
  width: calc(33% - 2.9rem);
  height: 14.5rem;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  border-radius: 0.5rem;
  overflow: auto;
  text-align: left;
  cursor: pointer;
}

.PortTradeHeader {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  font-size: var(--font-med-l);
}

.PortTradeCompanyHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: var(--font-med-l);
}

.PortTradeTable {
  display: grid;
  grid-template-columns:
    fit-content(100%) fit-content(100%) 2rem fit-content(100%)
    fit-content(100%);
  row-gap: 1.25rem; /* Gap between rows */
  column-gap: 0.5rem; /* Gap between label-value pairs */
  color: white;
  font-size: var(--font-med-l);
}

.PortTradeTableRow {
  display: contents; /* Allows each row’s children to act as grid items */
}

.PortTradeTable span {
  font-size: var(--font-med-l);
  width: fit-content;
  white-space: nowrap;
}

.PortTradeLabel {
  opacity: 0.7;
  color: var(--text-color);
}

.PortTradeValue {
  color: var(--text-color);
  width: fit-content;
}

.PortTradeROI {
  color: var(--stock-change-pos); /* Green color for positive ROI */
}

.PortTradePV {
  color: white; /* White color for PV */
}

.port-stats-row:hover {
  background-color: var(--hover-color);
}

.port-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--background-color);
  cursor: pointer;
  width: fit-content;
  overflow: auto;
  font-size: var(--font-med-l);
}

.port-cards-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1.5rem 0;
}

.port-card-edit {
  display: flex;
  gap: 1rem;
}

.port-card-edit-wrapper {
  background-color: transparent;
}

.port-card-input {
  background-color: transparent;
  border: var(--border);
  border-radius: 0.5rem;
  color: var(--text-color);
  font-size: var(--font-med-s);
  padding: 0 1rem;
}

.port-card-input::placeholder {
  color: var(--placeholder-color);
  font-family: 'SF Pro Display Bold', sans-serif;
}

.port-selected-name {
  text-align: left;
  font-size: var(--font-xl);
  color: var(--action-grey);
}

.port-card-selected {
  background-color: var(--stock-change-pos);
  color: #000;
}

.port-card-main-span {
  border-radius: 0.5rem;
  color: #fff;
  padding: 0.1rem 1rem;
  background-color: #000;
}

.port-card-main-span-selected {
  color: var(--stock-change-pos);
}

.port-stats-row td,
.port-stats-row th {
  line-height: 2.2rem;
}

.account-stats-container {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
}

.account-stats-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 2rem;
}

.account-stat-row {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-med-l);
  border-bottom: var(--border);
  padding: 1.5rem 0;
}

.account-stat-row span:first-child {
  color: var(--action-grey);
}

/* Portfolio breakdown styling */
.portfolio-breakdown-container {
  margin-top: 5rem;
}

.portfolio-breakdown-bar {
  display: flex;
  height: 1.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1rem;
  border: var(--border);
}

.portfolio-breakdown-segment {
  height: 100%;
}

.portfolio-breakdown-legend {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.portfolio-breakdown-legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: var(--font-med-l);
}

.portfolio-breakdown-legend-color {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
}

.port-stats-company {
  color: var(--action-grey);
}

.sort-filter-controls {
  display: flex;
  font-size: var(--font-med-l);
  align-items: center;
  gap: 1rem;
}

.port-trade-history-sort {
  display: flex;
  gap: 3rem;
  align-items: center;
  margin-bottom: 2rem;
}

.port-settings-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 1rem 0;
}

.port-settings-label {
  font-size: var(--font-med-l);
  color: var(--action-grey);
}

.port-settings-modal {
  position: absolute;
  z-index: var(--z-index-high);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--main-color);
  border: var(--border);
  padding: 2.5rem;
  box-sizing: border-box;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 2rem;
  overflow: auto;
}

.PortInputContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.PortSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: var(--background-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.PortSectionHeader {
  margin: 0;
  font-family: 'SF Pro Display Bold', sans-serif;
  font-size: var(--font-med-l);
}

.FeedPostHeaderH2 {
  margin: 0;
}

.FeedPortfolioSpan {
  font-size: var(--font-med-s);
  color: var(--action-grey);
  margin: 0;
}

.PortInputCol {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.PortModalSave {
  display: flex;
  gap: 1rem;
}
.PortSectionRow {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.PortCancelOrder {
  background-color: transparent;
  color: var(--text-color);
  font-size: var(--font-med-l);
  border: none !important;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .port-right-sidebar,
  .PortTrade {
    max-width: 100% !important;
  }
}
