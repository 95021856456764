@import url('variables.css');

.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-top:4.5rem;
}

.signup-box {
  text-align: center;
  margin-bottom: 2rem;
}

.signup-box h1 {
  font-size: var(--font-lg);
  font-family: 'SF Pro Display Bold';
  margin-bottom: 1rem;
}

.signup-box p {
  margin-bottom: 1rem;
}

.signup-box a,
.signup-box button.link-button,
.back-link {
  color: #1DCF5B;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: var(--font-sm);
  padding: 0;
  font-family: inherit;
}

.signup-box a:hover,
.signup-box button.link-button:hover,
.back-link:hover {
  text-decoration: underline;
}

.signup-box form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-box input {
  width: 25.29rem;
  height: 3.68rem;
  margin-bottom: 1rem;
  border: 1px solid #B3B3B3;
  border-radius: 0.4166rem;
  font-size: var(--font-med-l);
}

.signup-button {
  background: linear-gradient(90deg, #1DCF5B 0%, #59FF93 100%);
  color: #fff;
  border: none;
  border-radius: 4.167rem;
  font-size: var(--font-med-xs);
  cursor: pointer;
  font-family: 'SF Pro Display Bold';
  width:12.235rem;
  height: 3.1875rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.signup-button:hover {
  background-color: #17b64a;
}

.back-link-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.back-link {
  display: block;
  margin-top: 1rem;
  color: #333;
  text-decoration: none;
  text-align: center;
}

.back-link:hover {
  text-decoration: underline;
}

.signup-image {
  width: 100%;
  max-width: 42.5rem;
  height: auto;
  position: absolute;
  top:25rem;
  left:6.58rem;
}

.thank-you-box {
  text-align: center;
  margin-bottom: 2rem;
}

.thank-you-box p {
  font-size: var(--font-med-xl);
  color: #333;
}
