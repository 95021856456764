@import url('variables.css');

.pr-Profile {
  max-width: var(--content-width);
  margin: 0 auto; /* Center-align the container */
  padding: 0 3rem;
  padding-bottom: 2rem;
}

.pr-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  border-bottom: var(--border);
  height: 3rem;
  position: relative;
}

.pr-feed-wrapper {
  display: flex;
  align-items: center;
}

.pr-back-icon {
  font-size: var(--font-med-l);
  cursor: pointer;
}

.pr-title {
  font-size: var(--font-med-l);
  margin-left: 2rem;
}

.pr-username {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--font-med-l);
}

.pr-UsernameEditContainer,
.pr-NameEditContainer,
.pr-BioEditContainer,
.pr-SocialEditContainer,
.pr-WebsiteEditContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.pr-banner-image {
  width: 100%;
  height: 20rem;
  object-fit: cover;
}

.pr-profile-main-content {
  display: flex;
}

.pr-profile-sidebar {
  width: 100%;
  position: relative;
}

.pr-profile-info {
  padding: 1rem 0rem;
  text-align: left;
  position: relative;
  width: 100%;
  border-bottom: var(--border);
}
.pr-name-title {
  font-size: var(--font-lg);
  margin-bottom: 0.5rem;
}

.pr-main-profile-info-wrapper {
  margin-top: 4.5rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.pr-username-title {
  font-size: var(--font-med-l);
  color: var(--action-grey);
}

.pr-main-profile-content {
  width: 100%;
}

.pr-profile-picture-wrapper {
  position: absolute;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  top: -5.2rem;
}

.pr-pencil-container {
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
  background-color: #fff;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.083rem solid #000;
}

.pr-pencil-icon {
  color: #000; /* Black color for the pencil icon */
  font-size: var(--font-med-xl);
  cursor: pointer;
  width: 1.25rem;
}

.pr-profile-picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.pr-banner-picture-wrapper {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.pr-banner-picture {
  width: 100%;
  height: auto;
}

.pr-profile-info h2,
.pr-profile-info h3 {
  margin: 0;
  text-align: left;
}

.pr-profile-info h3 {
  margin: 0 0 2rem 0;
}

.pr-social-icon {
  cursor: pointer;
  max-width: 2rem;
}

.pr-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.pr-head-wrapper {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 1rem;
}

.pr-subscribe-btn,
.pr-follow-btn,
.pr-buy-btn {
  background-color: var(--background-color);
  color: var(
    --text-color
  ) !important; /* Important to override the default Mui-Dialog root color*/
  font-family: 'SF Pro Display Bold', sans-serif !important;
  border: none;
  border-radius: 1.67rem;
  cursor: pointer;
  font-size: var(--font-med-m);
  width: 7.86rem;
  height: 2.48rem;
}

.pr-bio {
  font-size: var(--font-med-l);
  overflow-wrap: break-word;
  margin-top: 0;
}

.pr-icon {
  font-size: var(--font-med-l);
  cursor: pointer;
}

.pr-followers-info {
  font-size: var(--font-med-m);
  color: var(--action-grey);
}

.pr-stats {
  margin: 1rem 0;
  font-size: var(--font-med-l);
  display: flex;
  gap: 5rem;
}

.pr-stats p {
  margin: 0.3rem 0;
}

.pr-social-media-icons {
  display: flex;
  justify-content: left;
  gap: 1rem;
  align-items: center;
}

.pr-returns,
.pr-subscription-packages {
  text-align: left;
  margin: 1rem 0;
}

.pr-returns h4,
.pr-subscription-packages h4 {
  margin-bottom: 0.5rem;
}

.pr-returns p {
  margin: 0.3rem 0;
}

.pr-positive {
  color: #4caf50;
}

.pr-negative {
  color: #f44336;
}

.pr-package {
  border: var(--border);
  border-radius: 0.417rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.pr-package h5 {
  margin-bottom: 0.5rem;
}

.pr-package ul {
  padding-left: 1rem;
  margin: 0.5rem 0;
}

.pr-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.pr-main-content {
  flex-grow: 1;
  padding: 1rem;
}

.pr-ProfileTopBar {
  display: flex;
  align-items: center;
  padding: 0.833rem 1.667rem;
  background-color: var(--color-main);
  justify-content: flex-start;
  border-bottom: var(--border);
}

.pr-ProfileTopBarNavItems {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.pr-ProfileTopBarNavButton {
  background: none;
  border: none;
  font-size: var(--font-med-xl);
  cursor: pointer;
  color: #333;
  position: relative;
  padding-bottom: 0.5rem;
  text-decoration: none;
}

.pr-ProfileTopBarNavButtonActive {
  color: var(--stock-change-pos);
}

.pr-package-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pr-ProfileTopBarNavButtonActive::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.25rem;
  background-color: var(--stock-change-pos);
  position: absolute;
  bottom: -1rem;
  left: 0;
}

.pr-feed-content {
  padding-top: 0rem;
}

.pr-LowerTopBarButtonProfile {
  background: none;
  border: none;
  font-size: var(--font-med-l);
  cursor: pointer;
  color: var(--text-color);
  display: flex;
  align-items: center;
}

.pr-LowerTopBarProfile {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  justify-content: left;
  gap: 1rem;
  border-bottom: var(--border);
  margin-left: 1rem;
}
.pr-EditInput {
  padding: 1rem;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: var(--font-med-l);
  width: 100%; /* Make input and textarea take full width of parent */
  box-sizing: border-box;
  border: none;
}

.pr-EditProfileContainer {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: left;
}
.pr-EditFieldContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pr-FieldName {
  width: 6.58rem;
  text-align: left;
  font-size: var(--font-med-l);
}

.pr-GuideText {
  font-size: var(--font-med-l);
  text-align: left;
  color: var(--action-grey);
}

.pr-EditProfileContainer h2 {
  font-size: var(--font-lg);
  text-align: left;
}
.pr-hr {
  border: var(--border);
  margin: 0;
}

.pr-MainEditWrapper {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.pr-SocialEditContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.pr-EditTextarea {
  width: 100%;
  height: 6rem;
  padding: 1rem;
  resize: none;
  font-size: var(--font-med-l);
  text-align: left;
  align-self: flex-start;
  background-color: var(--background-color);
  color: var(--text-color);
  box-sizing: border-box;
  border: none;
}

.pr-MainEditWrapper input,
.pr-MainEditWrapper textarea {
  border-radius: 0.5rem;
}

.pr-banner-image-wrapper {
  position: relative;
}

.crop-container {
  position: relative;
  width: 100%;
  height: 40vh;
  background: #333;
  margin-top: 1rem;
}

.crop-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.crop-actions input[type='range'] {
  width: 80%;
  background-color: red;
}

.crop-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.crop-buttons {
  width: auto;
  padding: 0.5rem 1rem;
}

.crop-container .reactEasyCrop_Image {
  border-radius: 0.5rem;
}

.pr-portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
}

.pr-graph-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pr-toggle-btn {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  align-self: flex-start;
  margin-bottom: 2rem;
}

.pr-portfolio-value {
  margin-top: 0rem;
}

.pr-stat-label {
  margin: 0;
  font-size: var(--font-med-l);
  color: var(--action-grey);
  display: inline-block;
  margin-bottom: 0.5rem;
}

.pr-stat-label-wrapper {
  display: flex;
  flex-direction: column;
}

/* Container for Username and Name */
.pr-NameUsernameContainer {
  display: flex;
  gap: 2rem;
}

/* Ensure both fields take equal space */
.pr-UsernameEditContainer,
.pr-NameEditContainer {
  flex: 1;
  width: 100%;
}

/* Bio and Website full width */
.pr-BioEditContainer,
.pr-WebsiteEditContainer {
  width: 100%;
}

/* Social container to ensure full width */
.pr-SocialEditContainer {
  width: 100%;
}

/* Social Inputs in two columns */
.pr-SocialInputsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  gap: 1rem;
  width: 100%; /* Full width of the container */
}

/* Ensure inputs inside the grid take full width */
.pr-SocialInputsContainer .pr-EditInput {
  width: 100%; /* Full width of each grid cell */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.ProfileContentWrapper {
  display: flex;
  flex: 1;
  padding-top: 2rem;
}

.ProfileMainContent,
.ProfilePortfolioContent {
  padding: 2rem;
  padding-top: 0rem;
  width: calc(50% - 2rem);
}

.ProfileMainContent {
  padding-right: 0rem;
}

.ProfilePortfolioContent {
  padding-left: 0rem;
}

.ProfilePortfolioContent {
  border-right: var(--border);
}

.ProfileContentHeader {
  text-align: left;
  margin: 0;
  font-size: var(--font-xl);
  cursor: pointer;
}

/* Mobile-specific styles */
@media only screen and (max-width: 768px) {
  .pr-Profile {
    padding-bottom: 75px;
    width: 100%; /* Make input and textarea take full width of parent */
    box-sizing: border-box;
    padding: 0 3.5rem 0 2.5rem;
  }
  .pr-profile-main-content {
    display: flex;
    flex-direction: column;
  }

  .ProfileContentWrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .ProfilePortfolioContent,
  .ProfileMainContent {
    width: 100%;
    padding: 1rem;
    display: none; /* Hidden by default */
  }

  .ProfilePortfolioContent.active,
  .ProfileMainContent.active {
    display: block; /* Show the active section */
  }

  .ProfileToggleButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    margin: 2rem 0;
  }
  .CreatePostFeedButton,
  .ProfileToggleButtonContainer button {
    border-radius: 30px !important;
    font-size: 12px;
    max-height: 35px;
    width: 80px;
  }

  .CreatePostFeedButton.greenButton {
    background-color: #00ff00;
    color: #000;
  }

  .pr-portfolio-container {
    margin-top: 0;
  }
}
